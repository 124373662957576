
import { AbstractControl, AsyncValidatorFn,  } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { CustomerApiService } from '../../service/customer.service';


export function UniqueUsernameValidator(authToken: string, customerId: string, customerApiService: CustomerApiService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {

        if (control.value !== null && control.value !== '') {
            const result = customerApiService.checkEndpointUsername(authToken, customerId, control.value);
            console.log(result);
            return result;
        } else {
            return of(null);
        }
    };
}
