import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from 'src/environments/environment';


@Component({
    selector: 'cassini-cti-app',
    templateUrl: './login-landing.html',
    styleUrls:[ './login-landing.scss' ]
})
export class LoginLandingComponent implements OnInit {

    grafana_url: string;

    public constructor(
        public router: Router,
        private renderer: Renderer2
    ) {}

    ngOnInit() {
        this.grafana_url = environment.grafana_login;
    }
}
