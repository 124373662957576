import { Component, SecurityContext } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ContactUsApiService } from 'src/app/service/contactus.service';
import { Router } from '@angular/router';
import { DomSanitizer, Title } from '@angular/platform-browser';

@Component({
    selector: 'cassini-cti-app',
    templateUrl: './sign-up.html',
    styleUrls: ['./sign-up.scss'],
})
export class SignUpComponent {
    signupForm = new UntypedFormGroup({
        organisationNameCtrl: new UntypedFormControl('', [
            Validators.required
        ]),
        nameCtrl: new UntypedFormControl('', [
            Validators.required
        ]),
        roleCtrl: new UntypedFormControl('', [
            Validators.required
        ]),
        contactDetailsCtrl: new UntypedFormControl('', [
            Validators.required
        ]),
    });

    get organisationNameCtrl() { return this.signupForm.get('organisationNameCtrl') }
    get nameCtrl() { return this.signupForm.get('nameCtrl') }
    get roleCtrl() { return this.signupForm.get('roleCtrl') }
    get contactDetailsCtrl() { return this.signupForm.get('contactDetailsCtrl') }

    constructor(
        private titleService: Title,
        private contactUsApi: ContactUsApiService,
        public router: Router,
        private sanitizer: DomSanitizer) {
            this.titleService.setTitle("Cassini CTI | Sign up to protect your organisation");
        }

    submitContact() {
        if (this.signupForm.valid) {
            let body = '\nOrganisation Name: ' + this.sanitizer.sanitize(SecurityContext.HTML, this.organisationNameCtrl.value) + '\n\n';
            body += 'Contact Name: ' + this.sanitizer.sanitize(SecurityContext.HTML, this.nameCtrl.value) + '\n\n';
            body += 'Contact Role: ' + this.sanitizer.sanitize(SecurityContext.HTML, this.roleCtrl.value) + '\n\n';
            body += 'Contact Details: ' + this.sanitizer.sanitize(SecurityContext.HTML, this.contactDetailsCtrl.value) + '\n\n';

            this.contactUsApi.postContactEnquiry('Sign-up enquiry', body).subscribe(res => {
                this.router.navigate(['/sign-up-success']);
            });
        }
    }
}

