<div class="content">
	<section class="page">
		<div class="container ">

			<h2>Frequently Asked Questions:</h2>
			<dl class="faq">

				<div>
					<dt>Where does Cassini get its threat intelligence from?</dt>
					<dd>
						Cassini partners with both the GCSB's National Cyber Security Centre for access to its
						Malware Free Networks feed (MFN), and CERT NZ for access to its phishing feed.
					</dd>
					<dd>
						For more information on these two feeds see <a href="/what-is-mfn/" title="What is MFN?">What is
							Malware Free Networks (MFN)?</a> and <a href="/what-is-certnz-phishing/"
							title="What is the CERT NZ Phishing feed?">What is the
							CERT NZ Phishing feed?</a>
					</dd>
				</div>

				<div>
					<dt>How do I connect to the Cassini CTI SaaS?</dt>
					<dd>There are a number of ways that your organisation's infrastructure can connect
						to the Cassini CTI system. If you use a supported firewall or SIEM then this
						integration can be as easy as configuring your system to talk to
						one of the Cassini APIs. If you require a more custom integration then
						Cassini can provide you with a software Agent to deploy along side your
						infrastructure which communicates with the Cassini CTI SaaS.</dd>
				</div>

				<div>
					<dt>What firewalls do you support?</dt>
					<dd>We support Fortigate firewalls via simple configuration. Contact us to
						become a beta-customer if you have Palo Alto or Checkpoint firewalls. Get in
						contact with us to discuss what your other firewall requirements are, there's a
						good chance that your firewall already supports our threat intelligence feed
						format.</dd>
				</div>

				<div>
					<dt>What SIEMs do you support?</dt>
					<dd>Cassini has proven agentless integration with Azure Sentinel allowing users to
						ingest indicators directly into their Microsoft Azure environments. Get in touch
						with us to discuss what other SIEM interactions you require, we may well have
						something in beta.</dd>
				</div>

				<div>
					<dt>Are you just another DNS Firewall?</dt>
					<dd>Not at all! DNS Firewalls are great but DNS only works with domain names. Blocking
						domain names is an important part of operationalising threat intelligence, but if
						that's all you're doing then you're missing two thirds of the picture. As well
						as allowing you to block domain names, Cassini CTI allows you to also
						block individual URLs as well as IP addresses. If you're going to protect your organisation
						using Threat Intelligence, make sure that you get the whole solution, not just
						one third of it.</dd>
				</div>

				<div>
					<dt>Do you have a REST API?</dt>
					<dd>We do for both Tasking (retrieving the latest indicators) and Telemetry (used for alerting and
						feedback).
						Please contact us if you have a specific need or integration in mind and we'll let you know if
						our REST API
						is the right way to go.
					</dd>
				</div>

				<div>
					<dt>How can I send telemetry back to Cassini CTI?</dt>
					<dd>We support a number of different ways to get telemetry reports back to Cassini.
						You can configure your infrastructure elements to use TLS Syslog to send
						Telemetry, or use our API for webhook-like integration. Is there something
						we've missed? Let us know, it might already be on our roadmap.</dd>
				</div>

				<div>
					<dt>What are Cassini CTI Agents?</dt>
					<dd>Cassini CTI Agents are the software components we supply that you deploy in your
						network. These components integrate with your network infrastructure (firewalls,
						proxy servers, DNS servers etc.) to provide the Indicators of Compromise to detect and disrupt.
						Our
						agents do this by using native integration points for the various network
						components they interact with ensuring integration is as low cost and straight forward as
						possible.</dd>
				</div>

				<div>
					<dt>Do I need to run Cassini CTI Agents?</dt>
					<dd>Probably not. We are able to achieve most integrations using simple configuration with our API.
						Get in touch to discuss what your network requirements are and we'll happily help you figure out
						the
						simplest solution.
					</dd>
				</div>

				<div>
					<dt>What sorts of Cassini CTI Agents are available?</dt>
					<dd>We are adding additional agents all the time. We have several off the shelf
						agents that support common DNS servers, Proxy Servers and Firewalls. However, if
						we don't already have something that meets your needs, or if you have special
						requirements we can build an agent to meet your specific needs.</dd>
				</div>

				<div>
					<dt>Something we haven't covered?</dt>
					<dd>Contact us for any further questions at <a href="#">info@cassini.nz</a>.</dd>
				</div>

			</dl>
		</div>
	</section>

	<section class="bottom-cta">
		<h2><strong>Protect</strong> your organisation today</h2>
		<div class="button alt"><a href="/sign-up/">Get Started Today</a></div>
	</section>
</div>