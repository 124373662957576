import {Component, OnInit} from "@angular/core";
import { Title } from "@angular/platform-browser";


@Component({
    selector: 'cassini-cti-app',
    templateUrl: './faq.html'
})
export class FAQComponent implements OnInit {

    public constructor(
        private titleService: Title
    ) { 
        this.titleService.setTitle("Cassini CTI | Frequently asked questions about Cassini CTI");
    }

    ngOnInit() {
    }
}


