import {Component, OnInit} from "@angular/core";
import { Title } from "@angular/platform-browser";


@Component({
    selector: 'cassini-cti-app',
    templateUrl: './about.html'
})
export class AboutComponent {

    public constructor(
        private titleService: Title
    ) { 
        this.titleService.setTitle("Cassini CTI | About");
    }

}

