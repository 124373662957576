import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { CustomerApiService } from '../../service/customer.service';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { Endpoint } from '../shared/endpoint.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { EndpointDataSource } from './endpoint-datasource.component';
import { merge } from 'rxjs';
import { AddEndpointDialogComponent } from './add-endpoint.component';
import { ConfirmDeleteDialogComponent } from '../../shared/confirm-delete-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { PortalUser } from '../shared/portal-user.model';
import { Auth } from 'aws-amplify';
import { tap } from 'rxjs/operators';
import { Title } from "@angular/platform-browser";


@Component({
    selector: 'cassini-cti-app',
    templateUrl: './endpoint-details.html',
    styleUrls: ['./endpoint-details.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
            state('expanded', style({ height: '*', visibility: 'visible' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})
export class EndpointDetailsComponent implements OnInit {

    currentUser: PortalUser

    addEndpointDialogRef: MatDialogRef<AddEndpointDialogComponent>;
    confirmDeletetDialogRef: MatDialogRef<ConfirmDeleteDialogComponent>;
    displayedColumns: Array<string>;
    dataSource: EndpointDataSource;
    loading = true;
    endpointCount = 10;
    selection = new SelectionModel<Endpoint>(true, []);
    highlightedRows = [];

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;

    constructor(
        private titleService: Title,
        public router: Router,
        public customerApiService: CustomerApiService,
        public dialog: MatDialog) {
            this.titleService.setTitle("Cassini CTI | Endpoint details");
    }

    ngOnInit(): void {
        this.dataSource = new EndpointDataSource(this.customerApiService);
        this.dataSource.count.subscribe(val => this.paginator.length = val);
        this.dataSource.loading$.subscribe(result => {
            this.loading = result;
        })

        Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(
            // User is authenticated
            user => {
                const token = user.getSignInUserSession().getIdToken().getJwtToken();
                this.currentUser = new PortalUser(user.attributes, token);

                if (this.currentUser.isAdmin) {
                    this.displayedColumns = ['created', 'name', 'description', 'lastActivity', 'delete'];
                } else {
                    this.displayedColumns = ['created', 'name', 'description', 'lastActivity'];
                }

                // reset the paginator after sorting
                this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

                
                merge(this.sort.sortChange, this.paginator.page)
                    .pipe(
                        tap(() => this.loadEndpointsPage())
                    )
                    .subscribe();

                this.loadEndpointsPage();
            },
            // User isn't authenticated.
            () => {
                this.router.navigate(['/login']);
            }
        );        
    }

    ngAfterViewInit() {
    }

    ngOnDestroy() {
    }

    loadEndpointsPage() {
        this.dataSource.loadEndpoints(
            this.currentUser.authToken,
            this.currentUser.customerId,
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize);
    }

    selectRow = (event, listItem: Endpoint) => {
        const index = this.highlightedRows.indexOf(listItem.id);
        if (index !== -1) {
            this.highlightedRows.splice(index, 1);
        } else {
            this.highlightedRows.push(listItem.id);
        }
    }

    isExpansionDetailRow = (i: number, row: Object) => {
        return row.hasOwnProperty('detailRow');
    }

    isSelectedRow = (item: Endpoint) => {
        if (item === undefined) {
            return false;
        }
        const result = (this.highlightedRows.indexOf(item.id) !== -1);
        return result;
    }

    getPassword = (item: Endpoint) => {
        if (item === undefined) {
            return "";
        }

        let result = item.password;
        if (item.hide) {
            result = '•'.repeat(item.password.length);
        }

        return result;
    }

    addEndpoint() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.position = {
            'top': '300px',
        };
        dialogConfig.data = {
            authToken: this.currentUser.authToken,
            customerId: this.currentUser.customerId
        }

        this.addEndpointDialogRef = this.dialog.open(AddEndpointDialogComponent, dialogConfig);
        this.addEndpointDialogRef.afterClosed().subscribe(result => {
            this.addEndpointDialogRef = null;
            if (result.success) {
                this.loading = true;
                this.customerApiService.addEndpoint(
                    this.currentUser.authToken,
                    this.currentUser.customerId,
                    result.data.name,
                    result.data.description,
                    result.data.username,
                    result.data.password
                ).subscribe(() => {
                    this.loading = false;
                    this.loadEndpointsPage();
                },
                error => {
                    this.loading = false;
                    console.error('addEndpoint error handler')
                    throw error;
                });
            }
        });
    }

    deleteEndpoint(event: Event, endpoint: Endpoint) {
        event.stopPropagation();
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.position = {
            'top': '300px',
        };

        this.confirmDeletetDialogRef = this.dialog.open(ConfirmDeleteDialogComponent, dialogConfig);
        this.confirmDeletetDialogRef.afterClosed().subscribe(result => {
            this.confirmDeletetDialogRef = null;
            if (result) {
                this.loading = true;
                this.customerApiService.deleteEndpoint(
                    this.currentUser.authToken,
                    this.currentUser.customerId,
                    endpoint.id
                ).subscribe(() => {
                    this.loading = false;
                    this.loadEndpointsPage();
                },
                error => {
                    this.loading = false;
                    console.error('addEndpoint error handler')
                    throw error;
                });
            }
        });
    }

    notifyEndpointApikeyCopied(e) {
        e.stopPropagation();
        // Provide a visual representation to the user that the apikey was copied by momentarily changing the 
        // icon to a tick and displaying the word COPIED.
        const element = new ElementRef(e.target);
        element.nativeElement.innerText = 'done';
        element.nativeElement.nextSibling.style.display = 'inline-block';
        setTimeout(() => {
            element.nativeElement.innerText = 'content_copy'
            element.nativeElement.nextSibling.style.display = 'none';
        }, 1000, element);
    }
}