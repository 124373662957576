import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-saved-dialog',
    template: `
        <h1 mat-dialog-title>Changes Saved</h1>
        <div mat-dialog-content><p>Your changes have been successfully saved.</p><div>
        <div mat-dialog-actions>
            <button mat-raised-button class="btn btn-success" (click)="dialogRef.close(true);">Close</button>
        </div>
    `,
    styles: ['div.mat-dialog-content { height: 110px; }']
})

export class SavedDialogComponent {
    constructor(public dialogRef: MatDialogRef<SavedDialogComponent>) {}
}