import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


import { AmplifyAngularModule, AmplifyService, AmplifyModules } from 'aws-amplify-angular';
import Auth from '@aws-amplify/auth';

import { QrCodeModule } from 'ng-qrcode';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';


// Website pages
import { PricingComponent } from "./public/pricing/pricing.component";
import { HomeComponent } from "./public/home/home.component";
import { AboutComponent } from "./public/about/about.component";
import { ContactComponent } from "./public/contact/contact.component";
import { ContactSuccessComponent } from "./public/contact-success/contact-success.component";
import { PageNotFoundComponent } from "./shared/not-found.component";
import { TermsComponent } from "./public/terms/terms.component";
import { SignUpComponent } from "./public/sign-up/sign-up.component";
import { SignUpSuccessComponent } from "./public/sign-up-success/sign-up-success.component";
import { FAQComponent } from "./public/faq/faq.component";
import { WhyCassiniComponent } from "./public/why-cassini/why-cassini.component";
import { WhatIsMFNComponent } from "./public/what-is-mfn/what-is-mfn.component";
import { WhatIsCERTNZPhishingComponent } from "./public/what-is-certnz-phishing/what-is-certnz-phishing.component";

// Authentication pages
import { LoginComponent } from "./public/auth/login/login.component";
import { LoginLandingComponent } from './public/auth/login-landing/login-landing.component';
import { LogoutComponent } from "./public/auth/logout/logout.component";
import { ForgotPasswordComponent } from "./public/auth/forgot/forgot-password.component";
import { ForgotPasswordStep2Component } from "./public/auth/forgot/forgot-password-step2.component";
import { SessionExpiredDialogComponent } from "./public/auth/login/session-expired.component";

// portal pages 
import { PortalComponent } from "./portal/portal.component";
import { FeedDetailsComponent } from "./portal/feed-details/feed-details.component";
import { EndpointDetailsComponent } from "./portal/endpoint-details/endpoint-details.component";
import { AddEndpointDialogComponent } from "./portal/endpoint-details/add-endpoint.component";
import { UserDetailsComponent } from "./portal/user-details/user-details.component";
import { AddEditContactDialogComponent } from "./portal/user-details/add-edit-contact.component";
import { UniqueUsernameValidator } from './portal/endpoint-details/endpoint-unique-username-validator.directive';
import { UsernameValidator } from './portal/endpoint-details/endpoint-username-validator';

// shared components
import { InfoButtonComponent } from './shared/info-popup/info-button/info-button.component';
import { InfoComponent } from './shared/info-popup/info/info.component';
import { InfoPopupDirective } from './shared/info-popup/info-popup.directive';
import { UnhandledErrorHandler } from "./shared/unhandled-error-handler.errorhandler";
import { ErrorDialogComponent } from "./shared/error-dialog.component";
import { SavedDialogComponent } from "./shared/saved-dialog.component";
import { ConfirmDeleteDialogComponent } from "./shared/confirm-delete-dialog.component";
import { CopyClipboardModule } from './shared/copy-clipboard.module';
import { ContactUsApiService } from "./service/contactus.service";
import { CustomerApiService } from "./service/customer.service";


import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatSortModule } from '@angular/material/sort'
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule, TooltipComponent } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { OverlayModule } from '@angular/cdk/overlay';

import { registerLocaleData } from '@angular/common';
import localeEnNZ from '@angular/common/locales/en-NZ';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';

registerLocaleData(localeEnNZ)

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        LoginLandingComponent,
        LogoutComponent,
        ForgotPasswordComponent,
        ForgotPasswordStep2Component,
        PortalComponent,
        FeedDetailsComponent,
        EndpointDetailsComponent,
        AddEndpointDialogComponent,
        UserDetailsComponent,
        AddEditContactDialogComponent,
        HomeComponent,
        AboutComponent,
        PricingComponent,
        TermsComponent,
        ContactComponent,
        ContactSuccessComponent,
        FAQComponent,
        WhyCassiniComponent,
        WhatIsMFNComponent,
        WhatIsCERTNZPhishingComponent,
        SignUpComponent,
        SignUpSuccessComponent,
        PageNotFoundComponent,
        ErrorDialogComponent,
        SavedDialogComponent,
        ConfirmDeleteDialogComponent,
        SessionExpiredDialogComponent,
        InfoButtonComponent,
        InfoComponent,
        InfoPopupDirective,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        HttpClientXsrfModule.withOptions({
            cookieName: 'XSRF-TOKEN',
            headerName: 'X-CSRFToken',
        }),
        BrowserAnimationsModule,
        MatExpansionModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatListModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatPasswordStrengthModule.forRoot(),
        MatTooltipModule,
        MatCardModule,
        OverlayModule,
        CopyClipboardModule,
        AmplifyAngularModule,
        QrCodeModule,
    ],
    exports: [
        MatExpansionModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatListModule,
        MatCheckboxModule,
        MatSlideToggleModule,
    ],
    providers: [
        { provide: ErrorHandler, useClass: UnhandledErrorHandler },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: MAT_DATE_LOCALE, useValue: 'en-NZ' },
        { provide: LOCALE_ID, useValue: 'en-NZ' },
        CustomerApiService,
        ContactUsApiService,
        {
            provide: AmplifyService,
            useFactory: () => {
                return AmplifyModules({
                    Auth
                });
            }
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
