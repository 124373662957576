<div class="content">
	<section class="page">
		<div class="container ">
			<h2>About Us</h2>
			<p class="subtext">Cassini CTI is brought to you by Cassini Limited. We specialise in Information Security
				consultancy and software development. We are New Zealand's leading experts in the
				field of STIX2/TAXII2 threat intelligence feeds with experience in both producing and consuming
				STIX2/TAXII2 APIs.
			</p>

			<h2>Our Leadership Team</h2>
			<p class="subtext">Our leadership team have years of industry experience in Information Security, Network Engineering, Software development, Solutions Architecture and Consulting.</p>
			<ul class="staff">

				<li>
					<div class="square-image"><img src="/assets/img/DeanPemberton.jpg" alt="Dean Pemberton" /></div>
					<div class="name"><a target="_blank" href="https://nz.linkedin.com/in/deanpemberton">Dean
							Pemberton<span></span></a></div>
					<div class="position">Director</div>
				</li>

				<li>
					<div class="square-image"><img src="/assets/img/JamesFitzsimons.jpg" alt="James Fitzsimons" /></div>
					<div class="name"><a target="_blank" href="https://nz.linkedin.com/in/jamesfitzsimons">James
							Fitzsimons<span></span></a></div>
					<div class="position">Director</div>
				</li>

			</ul>
		</div>
	</section>
</div>