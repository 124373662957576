<div class="content">
    <section class="page">
        <div class="container">
            <div class="row" id="loginRow">
                <div class="col-sm">
                    <button routerLink="/login" class="btn btn-cassini-primary btn-block">Portal Login</button>
                    <p>
                        Log into the Cassini CTI Portal to manage your organisations users, feeds and endpoints.
                    </p>
                </div>
                <div class="col-sm">
                    <a [href]="grafana_url" target="_blank"><button class="btn btn-cassini-primary btn-block">CTI Dashboards Login</button></a>
                    <p>
                        Log into the Cassini CTI Dashboards to view realtime information about your organisation detection and disruption activities.
                    </p>
                </div>
            </div>
            </div>
    </section>
</div>