<h2>User Details</h2>
<p>
    View and manage the users for your Organisation.
</p>

<mat-progress-spinner [class.hide-loading]="!loading" [mode]="'indeterminate'" [color]="'accent'" class="spinner">
</mat-progress-spinner>
<div class="container" [class.hide-loading]="loading">
    <div class="mat-elevation-z8">
        <mat-table #table [dataSource]="dataSource" matSort matSortActive="created" matSortDirection="desc"
            matSortDisableClear>

            <ng-container matColumnDef="created">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Created </mat-header-cell>
                <mat-cell *matCellDef="let contact"> {{contact.created | date:'short' }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
                <mat-cell *matCellDef="let contact"> {{contact.first_name}} {{contact.last_name}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Email Address </mat-header-cell>
                <mat-cell *matCellDef="let contact"> {{ contact.email_address }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="phone">
                <mat-header-cell *matHeaderCellDef> Phone Number </mat-header-cell>
                <mat-cell *matCellDef="let contact"> {{contact.phone_number }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="role">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Role </mat-header-cell>
                <mat-cell *matCellDef="let contact"> {{contact.role }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="admin">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Admin </mat-header-cell>
                <mat-cell *matCellDef="let contact">
                    <mat-checkbox disabled="true" [checked]="contact.is_primary"></mat-checkbox>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="delete">
                <mat-header-cell *matHeaderCellDef> Delete </mat-header-cell>
                <mat-cell *matCellDef="let contact">
                    <mat-icon *ngIf="contact.username !== currentUser.id" (click)="deleteContact(contact)">delete_forever</mat-icon>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="edit">
                <mat-header-cell *matHeaderCellDef> Edit </mat-header-cell>
                <mat-cell *matCellDef="let contact">
                    <a *ngIf="currentUser.isAdmin || contact.username == currentUser.id" [routerLink]="" (click)="editContact(contact)">Edit</a>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns" ></mat-row>
        </mat-table>
    </div>
    <mat-paginator [pageSizeOptions]="[10, 20, 50]" [length]="contactCount" [pageSize]="10" showFirstLastButtons>
    </mat-paginator>
    <p>
        <button *ngIf="currentUser && currentUser.isAdmin" type="button" mat-raised-button class="btn btn-success" (click)="addContact()">Add New User</button>
    </p>
</div>