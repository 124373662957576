import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ErrorDialogComponent } from './error-dialog.component';

@Injectable()
export class UnhandledErrorHandler implements ErrorHandler {
    
    constructor(public dialog: MatDialog, private ngZone: NgZone) {}
    
    errorDialogRef: MatDialogRef<ErrorDialogComponent>;
    
    handleError(error: any): void {
        this.ngZone.run(() => {
            console.error(error);
            this.displayError(error);
        });
    }

    displayError(error: any) {
        this.errorDialogRef = this.dialog.open(ErrorDialogComponent, {
            disableClose: false,
            maxWidth: 800,
        });

        if (error !== undefined) {
            this.errorDialogRef.componentInstance.errorMessage = error;
        }
    }
}