<section class="hero">
    <div class="text-container">
        <h1><strong>Cassini CTI</strong> </h1>
        <p class="subtext"><strong>Cassini Cyber Threat Intelligence</strong> helps protect your organisation from cyber attacks using threat intelligence from <strong>trusted</strong> New Zealand agencies.</p>
        <div class="cta button alt"><a href="/sign-up/">Get Started Today</a></div>
        <div>
            <img src="/assets/img/SplashScreen.jpg" alt="Protect" />
        </div>
    </div>
</section>

<div class="content">

    <section>
        <div class="container flex">
            
            <div class="text ">
                <h2>What is  <strong>Cassini CTI</strong>?</h2>
                <p><strong>Cassini Cyber Threat Intelligence</strong> Software as a Service enhances your organisation's defences
                    by blocking network traffic matching known bad urls, domain names and IP adresses.
                </p>
                <p>
                    It does this by aggregating multiple threat intelligence feeds provided by CERT NZ and the GCSB's National Cyber Security Centre, 
                    and provides simple integration with your existing network components.
                </p>
                <p>
                    For more information on these two feeds see <a href="/what-is-mfn/" title="What is MFN?">What is Malware Free Networks (MFN)?</a> and <a href="/what-is-certnz-phishing/" title="What is the CERT NZ Phishing feed?">What is the CERT NZ Phishing feed?</a>
                </p>
            </div>
            <div class="image">
                <img src="/assets/img/cassini_shield.png" alt="Cassini Shield" style="width: 350px;" />
            </div>
        </div>
    </section>


    <section>
        <div class="container flex">
            <div class="text ">
                <h2>Proven effectiveness</h2>
                <p>Read the case study we produced with CERT NZ on how Cassini CTI helped stop a sophisticated phishing campaign proporting to be the NZ Inland Revenue Department.</p>
                <p>
                    <img src="/assets/img/pdf.png" class="pdf-icon" />
                    <a href="/assets/IRD-Phishing-case-study.pdf" target="_blank" class="casestudy">
                        Case study: Timely threat intelligence saves New Zealanders’ tax dollars.
                    </a>
                </p>                
            </div>
            <div class="image">
                <img src="/assets/img/ird_phishing.png" class="screenshot" title="IRD Phishing email" alt="IRD Phishing email screenshot" />
            </div>
        </div>
    </section>

    <section>
        <div class="container flex">
            <div class="text ">
                <h2>Simple <strong>Integration</strong> with your existing network</h2>
                <p><strong>Cassini CTI</strong> is designed to integrate with your network via simple configuration changes. We use standard APIs to deliver
                    <strong>Detection</strong> and <strong>Disruption</strong> capability to your existing network
                    components with minimal effort required by your operations team.
                </p>
                <p>
                    Cassini CTI integrates with a number of industry standard products include firewalls, SIEMs, DNS
                    servers, and HTTP Proxy servers, and if we don't have an existing integration that meets your organisation's
                    specific needs, we can work with you to add one.
                </p>
            </div>
            <div class="image">
                <img src="/assets/img/NetworkDiagram.jpg" alt="Network Diagram" class="network-integration" />
            </div>
        </div>
    </section>

    <section>
        <div class="container flex">
            <div class="text ">
                <h2>Quantifiable <strong>Detection</strong> and <strong>Disruption</strong></h2>
                <p><strong>Cassini CTI Dashboards</strong> provide detection and disruption metrics across subscribed
                    feeds.</p>
                <p>
                    Provide your stakeholders with the information they need to understand potential security events
                    occuring on your organisation's network in near real time.
                </p>
            </div>
            <div class="image">
                <img src="/assets/img/sightings.png" alt="Sightings" class="screenshot " />
            </div>
        </div>
    </section>

    <section class="bottom-cta">
        <h2><strong>Protect</strong> your organisation today</h2>
        <div class="button alt"><a href="/sign-up/">Get Started Today</a></div>
    </section>
</div>

<router-outlet></router-outlet>