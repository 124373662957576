export class FeedAuth {
    constructor(
        public username?: string,
        public password?: string,
        public access_token_url?: string,
        public client_id?: string,
        public client_secret?: string,
        public grant_type?: string,
        public scope?: string,
    ) { }
}
