import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-delete-dialog',
    template: `
        <h1 mat-dialog-title>Confirm Delete</h1>
        <div mat-dialog-content>
            <p>Are you sure you want to delete?</p>
            <p>This action cannot be undone.</p>
        <div>
        <div mat-dialog-actions>
            <button mat-raised-button class="btn" (click)="dialogRef.close(false);">Cancel</button>
            <button mat-raised-button class="btn btn-success" (click)="dialogRef.close(true);">Confirm</button>
        </div>
    `,
    styles: ['div.mat-dialog-content { height: 150px; }', '.mat-dialog-actions button { width: 100px; margin-right: 15px; }']
})

export class ConfirmDeleteDialogComponent {
    constructor(public dialogRef: MatDialogRef<ConfirmDeleteDialogComponent>) {}
}