import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CustomerApiService } from 'src/app/service/customer.service';
import { Contact } from '../shared/contact.model';

export class ContactDataSource implements DataSource<Contact> {

    private contactsSubject = new BehaviorSubject<Contact[]>([]);
    private countSubject = new BehaviorSubject<number>(0);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    constructor(private customerService: CustomerApiService) { }

    get data(): Contact[] {
        return this.contactsSubject.value;
    }

    public count = this.countSubject.asObservable();

    connect(collectionViewer: CollectionViewer): Observable<Contact[]> {
        return this.contactsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.contactsSubject.complete();
        this.loadingSubject.complete();
    }

    loadContacts(authToken: string, customerId: string, sortBy = 'created', sortOrder = 'desc', pageNumber = 0, pageSize = 3) {

        this.loadingSubject.next(true);

        this.customerService.getContacts(authToken, customerId, sortOrder, sortBy, pageNumber, pageSize)
            .pipe(
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(results => {
                this.contactsSubject.next(results.contacts);
                this.countSubject.next(results.count);
            });
    }
}
