import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Endpoint } from '../shared/endpoint.model';
import { CustomerApiService } from '../../service/customer.service'

export class EndpointDataSource implements DataSource<Endpoint> {

    private endpointsSubject = new BehaviorSubject<Endpoint[]>([]);
    private countSubject = new BehaviorSubject<number>(0);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    constructor(private customerService: CustomerApiService) { }

    get data(): Endpoint[] {
        return this.endpointsSubject.value;
    }

    public count = this.countSubject.asObservable();

    connect(collectionViewer: CollectionViewer): Observable<Endpoint[]> {
        return this.endpointsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.endpointsSubject.complete();
        this.loadingSubject.complete();
    }

    loadEndpoints(authToken: string, customerId: string, sortBy = 'created', sortOrder = 'desc', pageNumber = 0, pageSize = 3) {

        this.loadingSubject.next(true);

        this.customerService.getEndpoints(authToken, customerId,sortOrder, sortBy, pageNumber, pageSize)
            .pipe(
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(results => {
                const rows = [];
                results.endpoints.forEach(item => { 
                    item.hide = true;
                    rows.push(item, { detailRow: true, 'detail': item })
                });
                this.endpointsSubject.next(rows);
                this.countSubject.next(results.count);
            });
    }
}
