<div class="content">
	<section class="page">
		<div class="container max-container">

			<h2>Let's get started today</h2>

			<p class="subtext">Let us know a little bit about you so we can help you take the first step towards protecting your organisation with Cassini CTI.</p>

			<div class="contact-box">
				<div class="contact-details">

					<form [formGroup]="signupForm">
						<h3>Your Contact Details</h3>
						<div class="form-group">
							<mat-form-field class="mat-form-field-800">
								<label>Organisation Name:</label>
								<input type="text" matInput formControlName="organisationNameCtrl">
							</mat-form-field>
							<div *ngIf="organisationNameCtrl.invalid && (organisationNameCtrl.dirty || organisationNameCtrl.touched)"
								class="alert alert-danger">
								<div *ngIf="organisationNameCtrl.errors.required">
									Please provide your organisation name.
								</div>
							</div>                  
						</div>
						<div class="form-group">
							<mat-form-field class="mat-form-field-800">
								<label>Your Name:</label>
								<input type="text" matInput formControlName="nameCtrl">
							</mat-form-field>
							<div *ngIf="nameCtrl.invalid && (nameCtrl.dirty || nameCtrl.touched)"
								class="alert alert-danger">
								<div *ngIf="nameCtrl.errors.required">
									Please tell us who you are.
								</div>
							</div>                 
						</div>
						<div class="form-group">
							<mat-form-field class="mat-form-field-800">
								<label>Your Role:</label>
								<input type="text" matInput formControlName="roleCtrl">
							</mat-form-field>
							<div *ngIf="roleCtrl.invalid && (roleCtrl.dirty || roleCtrl.touched)"
								class="alert alert-danger">
								<div *ngIf="roleCtrl.errors.required">
									Please let us know what your role is.
								</div>
							</div>                
						</div>
						<div class="form-group">
							<mat-form-field class="mat-form-field-800">
								<label>Tell us the best way to contact you:</label>
								<textarea matInput required formControlName="contactDetailsCtrl"
            						cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="10"></textarea>
							</mat-form-field>
							<div *ngIf="contactDetailsCtrl.invalid && (contactDetailsCtrl.dirty || contactDetailsCtrl.touched)"
								class="alert alert-danger">
								<div *ngIf="contactDetailsCtrl.errors.required">
									Let us know how best to contact you. It's always a good idea to provide a phone number
									and email address if possible!
								</div>
							</div>               
						</div>
						<button type="submit" class="btn btn-cassini-primary" [disabled]="signupForm.invalid"
							(click)="submitContact()">Send</button>
					</form>
				</div>
			</div>

		</div>
	</section>
</div>