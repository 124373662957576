import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { ContactUsEnquiry } from '../public/contact/contact-us-enquiry.model';


@Injectable()
export class ContactUsApiService {
    private static _router: Router;

    constructor(private http: HttpClient,
        private router: Router) {
    }

    private static _handleError(err: HttpErrorResponse | any) {
        if (err.status === 401 || err.status === 403) {
            // redirect back to an unauthorised page
            ContactUsApiService._router.navigate(['unauthorised']);
        } else {
            console.error(err.message);
        }
        return throwError(err.error || err.json() || err || 'Server error');
    }


    // Submit contact enquiry
    postContactEnquiry(subject: string, body: string): Observable<any> {
        const headers = new HttpHeaders({
                'x-api-key': `${environment.contact_apikey}`
            });
        const contactEnquiry = new ContactUsEnquiry(subject, body);

        return this.http
            .post<ContactUsEnquiry>(`${environment.contact_api}/contact-us/`, contactEnquiry, { headers: headers})
            .pipe(map(res => res),
                catchError(ContactUsApiService._handleError));
    }
}