<div class="content">
	<section class="page">
		<div class="container ">
			<img src="/assets/img/certnz-everyday-rgb-pos-600px.png" alt="CERT NZ logo" title="CERT NZ logo" class="about-cert-logo">
			<h2>What is the CERT NZ Phishing feed?</h2>
			<dl class="faq">

				<div>
					<dt>What is the CERT NZ Threat Intelligence (Phishing) feed?</dt>
					<dd>CERT NZ’s Threat Intelligence API allows organisations to disrupt phishing attacks targeting New
						Zealanders and New Zealand organisations. CERT NZ authored indicators are from information
						sources for campaigns specifically affecting New Zealanders, and misrepresenting New Zealand
						brands. This feed includes unique, CERT NZ authored content, curated by CERT NZ experts that
						provide a high level of confidence in the indicators</dd>
				</div>

				<div>
					<dt>How are CERT NZ authored indicators created?</dt>
					<dd>Indicators in the CERT NZ feeds are produced using the following steps:
						<ul>
							<li>Phishing email received by a member of the New Zealand community.</li>
							<li>They report it to CERT NZ</li>
							<li>CERT NZ extracts the indicators out of the message</li>
							<li>CERT NZ analyses, filters and enriches the indicators</li>
							<li>CERT NZ publishes the indicators via the API to end users</li>
							<li>Indicators are used by partners such as Cassini to allow their customers to identify and block
						threats</li>
						</ul>
					</dd>
				</div>

				<div>
					<dt>Can I consume the CERT NZ feed directly from CERT NZ?</dt>
					<dd>CERT NZ allows limited direct access to its feeds. As with MFN, it is expected that individual
						companies will consume the CERT NZ feed through partners.
						To ensure that the sensitive data provided via the API is limited to those able to act on and
						protect the sensitivity of it, CERT NZ will only consider the following types of organisation
						for their eligibility to enter into an access and use agreement to receive this confidential
						information:
						<ul>
							<li>Internet Service Providers (ISPs)</li>
							<li>Security Service Providers (SSPs)</li>
							<li>Hosting Service Providers (HSPs)</li>
							<li>Organisations engaged in cyber security research and development (R&D)</li>
							<li>Large Network Operators (LNO)</li>
						</ul>
						
						Collectively, it is expected that eligible types of organisations will be able to provide
						significant coverage and protection for New Zealand against cyber security threats.</dd>
				</div>

				<div>
					<dt>Something we haven't covered?</dt>
					<dd>Contact us for any further questions at <a href="#">info@cassini.nz</a>.</dd>
				</div>

			</dl>
		</div>
	</section>

	<section class="bottom-cta">
		<h2><strong>Protect</strong> your organisation today</h2>
		<div class="button alt"><a href="/sign-up/">Get Started Today</a></div>
	</section>
</div>