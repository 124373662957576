import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-saved-dialog',
    template: `
        <h1 mat-dialog-title>Session Expired</h1>
        <div mat-dialog-content><p>Your session has expired. You will need to login again.</p><div>
        <div mat-dialog-actions>
            <button mat-raised-button class="btn btn-success" (click)="dialogRef.close(true);">Return to login</button>
        </div>
    `,
    styles: ['div.mat-dialog-content { height: 110px; }']
})

export class SessionExpiredDialogComponent {
    constructor(public dialogRef: MatDialogRef<SessionExpiredDialogComponent>) {}
}