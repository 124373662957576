<div class="content">
	<section class="page">
		<div class="container min-container">

			<h2>We'll be in touch!</h2>
			<p class="subtext">Thanks for your interest! One of our team will be in touch ASAP to discuss more about
				your organisations needs, and how Cassini CTI can help.</p>
				<p class="subtext">Return <a routerLink='/home'>home</a></p>
		</div>
	</section>
</div>