import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { LoginComponent } from "./public/auth/login/login.component";
import { LoginLandingComponent } from './public/auth/login-landing/login-landing.component';
import { LogoutComponent } from "./public/auth/logout/logout.component";
import { ForgotPasswordComponent } from "./public/auth/forgot/forgot-password.component";
import { ForgotPasswordStep2Component } from "./public/auth/forgot/forgot-password-step2.component";

import { HomeComponent } from "./public/home/home.component";
import { AboutComponent } from "./public/about/about.component";
import { ContactComponent } from "./public/contact/contact.component";
import { ContactSuccessComponent } from './public/contact-success/contact-success.component';
import { PageNotFoundComponent } from "./shared/not-found.component";

import { PricingComponent } from "./public/pricing/pricing.component";
import { TermsComponent } from "./public/terms/terms.component";
import { SignUpComponent } from "./public/sign-up/sign-up.component";
import { SignUpSuccessComponent } from "./public/sign-up-success/sign-up-success.component";
import { FAQComponent } from "./public/faq/faq.component";
import { WhyCassiniComponent } from "./public/why-cassini/why-cassini.component";
import { WhatIsMFNComponent } from "./public/what-is-mfn/what-is-mfn.component";
import { WhatIsCERTNZPhishingComponent } from "./public/what-is-certnz-phishing/what-is-certnz-phishing.component";

import { PortalComponent } from "./portal/portal.component";
import { FeedDetailsComponent } from "./portal/feed-details/feed-details.component";
import { EndpointDetailsComponent } from './portal/endpoint-details/endpoint-details.component';
import { UserDetailsComponent } from "./portal/user-details/user-details.component";


const homeRoutes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'contact-success', component: ContactSuccessComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'sign-up', component: SignUpComponent },
  { path: 'sign-up-success', component: SignUpSuccessComponent },
  { path: 'faq', component: FAQComponent },
  { path: 'why-cassini', component: WhyCassiniComponent },
  { path: 'what-is-mfn', component: WhatIsMFNComponent },
  { path: 'what-is-certnz-phishing', component: WhatIsCERTNZPhishingComponent },
  { path: 'login-landing', component: LoginLandingComponent },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'forgot-password/:email', component: ForgotPasswordStep2Component },
    
  { path: '', component: HomeComponent }
];

const secureHomeRoutes: Routes = [
  {

    path: '',
    redirectTo: '/portal/',
    pathMatch: 'full'
  },
  {
    path: 'portal', component: PortalComponent, children: [
      { path: 'logout', component: LogoutComponent },
      { path: 'user-details', component: UserDetailsComponent },
      { path: 'feed-details', component: FeedDetailsComponent },
      { path: 'endpoint-details', component: EndpointDetailsComponent },
      { path: '', component: FeedDetailsComponent }]
  }
];

const routes: Routes = [
  {
    path: '',
    children: [
      ...homeRoutes,
      ...secureHomeRoutes,
      {
        path: '',
        component: HomeComponent
      }
    ],
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
