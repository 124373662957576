<div class="content">
	<section class="page">
		<div class="container ">

			<h2>What sort of organisation are you?</h2>


			<p class="subtext">Our Cassini CTI plans help you to incorporate New Zealand's most
				critical threat intelligence feeds into your business.</p>

			<div class="plans">

				<ul class="plan smallplan">
					<li>
						<h3>Single organisation</h3>
					</li>

					<li class="price">$499 NZD<span class="month"> / mth</span></li>

					<li>Standard Dashboards</li>

					<li>1 Endpoint integration included</li>
					<li>Additional endpoints:</li>
					<ul class="endpoint-costs">
						<li class="endpoint-cost-item">Endpoints 2 - 20: $100 / mth per endpoint</li>
						<li class="endpoint-cost-item">Additional Endpoints - contact us for pricing</li>
					</ul>
					
					<li class="pricing-cta">
						<div class="button alt"><a href="/sign-up/">Get started</a></div>
					</li>

				</ul>

				<ul class="plan enterpriseplan">
					<li>
						<h3>Service Provider</h3>
					</li>

					<li class="price">Contact us for our best price.</li>

					<li>Custom Dashboards</li>

					<li>Suitable for service providers
					</li>

					<li>Number of endpoint integrations dependent on plan.</li>

					<li class="pricing-cta">
						<div class="button alt"><a href="/sign-up/">Get started</a></div>
					</li>

				</ul>

			</div>


			<h2>Pricing FAQ</h2>
			<dl class="faq">

				<div>
					<dt>Do you offer a trial account?</dt>
					<dd>Yes, get in <a href="/contact">contact with us</a> to discuss how we can help you with your trial needs.</dd>
				</div>

				<div>
					<dt>Can I change the number of endpoints I have deployed, or add additional endpoints types?</dt>
					<dd>We'd be happy to help you deploy additional endpoints, or different endpoint types to support
						additional integrations. Please note that changing the number of endpoints may incur additional 
						charges.</dd>
				</div>

				<div>
					<dt>Do you offer pricing for nonprofit organizations?</dt>
					<dd>Yes, make an enquiry on our <a href="/sign-up">sign-up page</a> and we'll set you up on our nonprofit pricing.</dd>
				</div>

				<div>
					<dt>Something we haven't covered?</dt>
					<dd>Contact us for any further questions at <a href="#">info@cassini.nz</a>.</dd>
				</div>

			</dl>


		</div>
	</section>
	<section class="bottom-cta">
        <h2><strong>Protect</strong> your organisation today</h2>
        <div class="button alt"><a href="/sign-up/">Get Started Today</a></div>
    </section>
</div>