<div class="content">
	<section class="page">
		<div class="container">
			<h2>Cassini CTI SaaS Terms of Use</h2>
			<ol>
				<li clause="1">APPLICATION OF TERMS
					<ol>
						<li clause="1.1">These Terms apply to all of our Services (as that term is defined below). By
							using any Service:
							<ol>
								<li clause="1.1.1">you agree to these Terms; and</li>
								<li clause="1.1.2">where your use is on behalf of another person (e.g. a company), you
									confirm
									that you are authorised to, and do in fact, agree to these Terms on that person’s
									behalf and
									that, by agreeing to these Terms on that person’s behalf, that person is bound by
									these
									Terms.</li>
							</ol>
						</li>
					</ol>
				</li>
				<li clause="2">CHANGES
					<ol>
						<li clause="2.1">We may change these Terms at any time by notifying you of the change by email
							or by posting
							a notice on the Website. Unless stated otherwise, any change takes effect from the date set
							out in the notice. You are responsible for ensuring you are familiar with the latest Terms.
							By continuing to access and use the Service from the date on which the Terms are changed,
							you agree to be bound by the changed Terms.</li>
						<li clause="2.2">These Terms were last updated on <strong>16 April 2021.</strong></li>
					</ol>
				</li>
				<li clause="3">INTERPRETATION
					<p>In these Terms:</p>
					<p><span class="term">Cassini CTI Software</span> means the software owned by us (and our licensors)
						that is used to provide the Services.</p>
					<p><span class="term">Cassini CTI Service</span> means the Service provided by us using our
						Underlying Systems.</p>
					<p><span class="term">Confidential Information</span> means any information that is not public
						knowledge and that is obtained from the other party in the course of, or in connection with, the
						provision and use of the Service. Our Confidential Information includes Intellectual Property
						owned by us (or our licensors), including the Cassini CTI Software. Your Confidential
						Information includes the Data.</p>
					<p><span class="term">Data</span> means all data, content, and information (including personal
						information) owned, held, used or created by you or on your behalf that is stored using, or
						inputted into the Service.</p>
					<p><span class="term">Deliverable</span> means any output of Development and Support Services
						described in a Statement of Work and produced by us for you including any designs, code, or
						compiled software. </p>
					<p><span class="term">Development and Support Services</span> means any software development
						services, including the integration of the Cassini CTI Service with your systems and networks,
						and any support, including telephone or email responses to queries or issues, we provide to you
						in respect of the Cassini CTI Service or otherwise.</p>
					<p><span class="term">Feed</span> means Data inputted into and stored in the service on your behalf
						from a Feed Provider.</p>
					<p><span class="term">Feed Provider</span> means a third party that provides a Feed to us for your
						use within the Service. </p>
					<p><span class="term">Fees</span> means the fees agreed in writing between you and us, as may be
						updated from time to time in accordance with clause 8.5, or (where no fees have been agreed) our
						Standard Rates.</p>
					<p><span class="term">Force Majeure</span> means an event that is beyond the reasonable control of a
						party, excluding:</p>
					<ul>
						<li>an event to the extent that it could have been avoided by a party taking reasonable
							steps or reasonable care; or</li>
						<li>a lack of funds for any reason.</li>
					</ul>
					<p><span class="term">including</span> means "including (without limitation)" and similar words do
						not imply any limit.</p>
					<p><span class="term">Intellectual Property Rights</span> means all industrial and intellectual
						property rights throughout the world and includes copyright and all rights existing anywhere in
						the world conferred under statute, common law or equity relating to inventions (including
						patents), registered and unregistered trademarks and designs, circuit layouts, data and
						databases, confidential information, know-how, and all other rights resulting from intellectual
						activity. Intellectual Property has a consistent meaning, and includes any enhancement,
						modification or derivative work of the Intellectual Property.</p>
					<p><span class="term">Objectionable</span> means giving rise (in our sole and exclusive opinion) to
						distaste or offence and includes being exploitative, fraudulent, misleading, objectionable,
						defamatory, obscene, harassing, threatening, harmful, or unlawful in any way.</p>
					<p>a <span class="term">party</span> includes that party’s permitted assigns.</p>
					<p><span class="term">Permitted Users</span> means your personnel who are authorised to access and
						use the Service on your behalf in accordance with clause 6.3. </p>
					<p>a <span class="term">person</span> means any entity recognised by law including an individual, a
						company, a partnership, any kind of body corporate, any association of persons (whether
						incorporated or not), a trust, a government department, and a Crown entity.</p>
					<p><span class="term">personal information</span> has the meaning given in the Privacy Act 2020.</p>
					<p><span class="term">personnel</span> includes officers, employees, contractors and agents, but a
						reference to your personnel does not include us.</p>
					<p><span class="term">Services</span> means the services we provide now or in the future and <span class="term">Service</span>
						means any one of them, as the context requires. This includes the Cassini CTI Service having the
						core functionality described on the Website, as the Website is updated from time to time, and
						any Development and Support Services.</p>
					<p><span class="term">Standard Rates</span> mean our ordinary hourly labour rates for Services,
						determined by us from time to time and for each engagement, and available on request together
						with our reasonable expenses incurred in providing such Services. </p>
					<p><span class="term">Start Date</span> means the date that you first access or use the Service.
					</p>
					<p><span class="term">Statement of Work</span> means a scope of Development and Support
						Services agreed between us in writing and containing a description of the relevant Services, the
						applicable Fees, any applicable performance standards or acceptance tests, and any Deliverables.
					</p>
					<p><span class="term">Telemetry</span> means information about the effectiveness, and your use, of the 
						threat information provided by the Feeds and our Services. Telemetry may be Basic Telemetry which 
						only identifies that a threat indicator has been detected and when that detection happened, 
						or Enhanced Telemetry which in addition includes information about where on your network the threat 
						was detected (including IP address).
					</p>
					<p><span class="term">Terms</span> means these terms titled Cassini CTI SaaS Terms of Use.</p>
					<p><span class="term">Underlying Systems</span> means the Cassini CTI Software, IT solutions,
						systems and networks (including software and hardware) used to provide the Services, including
						any third party solutions, systems and networks.</p>
					<p><span class="term">use</span> in relation to our Services means to use in any way including to
						access in any way or to receive such Services. </p>
					<p><span class="term">We, us</span> or <span class="term">our</span> means Cassini Limited, company
						number 7431436.</p>
					<p><span class="term">Website</span> means the internet site at https://cassini.nz, or such other
						site notified to you by us.</p>
					<p><span class="term">Year</span> means a 12-month period starting on the Start Date or the
						anniversary of that date.</p>
					<p><span class="term">You</span> or <span class="term">your</span> means you or, if clause 1.1.2
						applies, both you and the other person on whose behalf you are acting.</p>
					<p>Words in the singular include the plural and vice versa.</p>
					<p>A reference to a statute includes references to regulations, orders or notices made under or in
						connection with the statute or regulations and all amendments, replacements or other changes to
						any of them.</p>
				</li>
				<li clause="4">PROVISION OF SERVICES
					<ol>
						<li clause="4.1">
							We must use reasonable efforts to provide our Services:
							<ol>
								<li clause="4.1.1">in accordance with these Terms and New Zealand law;</li>
								<li clause="4.1.2">exercising reasonable care, skill and diligence; and</li>
								<li clause="4.1.3">using suitably skilled, experienced and qualified personnel.</li>
							</ol>

						</li>
						<li clause="4.2">Our provision of Services to you is non-exclusive. Nothing in these Terms
							prevents us from
							providing Services to any other person.</li>
						<li clause="4.3">Subject to clauses 4.5 and 4.6, we must use reasonable efforts to ensure the
							Cassini CTI
							Service is available on a 24/7 basis. </li>
						<li clause="4.4">Services may be unavailable due to an internet or network disruption or outage
							or we may
							make a Service, or parts of it, unavailable temporarily for security reasons, to permit
							maintenance, or to enable other development activity to take place, or in the event of Force
							Majeure. We must use reasonable efforts to publish on the Website or notify you by email
							advance details of any unavailability of any Service.</li>
						<li clause="4.5">Through the use of web services and APIs, some of our Services interoperate
							with a range of
							third party service features. We do not make any warranty or representation on the
							availability of those features. Without limiting the previous sentence, if a third party
							feature provider ceases to provide that feature or ceases to make that feature available on
							reasonable terms, we may cease to make available that feature to you. To avoid doubt, if we
							exercise our right to cease the availability of a third party feature, you are not entitled
							to any refund, discount or other compensation.</li>
						<li clause="4.6">We may choose to impose limits on the Services at any time and for any reason.
							Limits may
							include restrictions on the region or type of device or number of calls to a Service in any
							given period that you are permitted to make. If we impose such limits, we may describe them
							on our website but we are not required to do so or to notify you beforehand. If you exceed
							the quantity of calls any limit imposed on to a Service, we may deny your access or charge
							additional fees for the excess (except that where additional fees will apply we must give
							you prior notice). </li>
						<li clause="4.7">Abusive or excessive use of a Service (as determined by us) may result in the
							temporary
							suspension or termination of your access to that, or any other, Service. If your use of a
							Service is likely to generate a volume of calls materially in excess of what an average
							Service user would generate please contact us first and we may be able to agree to an
							arrangement in relation to your proposed use case.</li>
					</ol>
				</li>
				<li clause="5">DEVELOPMENT AND SUPPORT SERVICES
					<ol>
						<li clause="5.1">You may engage Development and Support Services by entering into a Statement of
							Work with us
							for those Development and Support Services.
						</li>
						<li clause="5.2">In case of any conflict between a Statement of Work and these Terms, these
							Terms will
							prevail except to the extent you and we have clearly agreed in the Statement of Work a
							variation to these Terms.
						</li>
						<li clause="5.3">You must do all things reasonably required by us to facilitate our provision of
							the
							Development and Support Services.
						</li>
						<li clause="5.4">In the case we provide Development and Support Services to you which are not
							provided for in
							a Statement of Work these Terms and our Standard Rates will apply.
						</li>
					</ol>
				</li>
				<li clause="6">YOUR OBLIGATIONS
					<ol>
						<li clause="6.1">You and your personnel must:
							<ol>
								<li clause="6.1.1">use our Services in accordance with these Terms solely for:
									<ul>
										<li class="alpha"> in the case of the Cassini CTI Service, its intended purpose,
											being the
											purpose of detecting and disrupting network connections matching indicators
											of compromise
											provided by the Service on a network solely owned by you (unless otherwise
											agreed in
											writing by us in a Statement of Work); and</li>
										<li class="alpha"> in the case of all of our Services, for lawful purposes which
											do
											not
											infringe the rights of any third party; and</li>
									</ul>
								</li>
								<li clause="6.1.2">not purchase, use, or access our Services for the purpose of building
									a competitive
									product or service or for any other competitive purposes.</li>
								<li clause="6.1.3">not misuse our Services by interfering with their normal operation,
									or attempting to
									access them using a method other than through the interfaces and instructions that
									we
									provide.</li>
								<li clause="6.1.4">not circumvent or attempt to circumvent any limitations that we may
									choose to impose
									on
									your account.</li>
								<li clause="6.1.5">unless authorised by us in writing, not probe, scan, or test the
									vulnerability of
									any
									Service or any Cassini system or network.</li>
								<li clause="6.1.6">unless authorised by us in writing, not use any manual or automated
									system or
									software
									to extract or scrape data from our Services or other interfaces through which we
									make
									our Services available.</li>
								<li clause="6.1.7">not use our Services in any way that might impair functionality or
									interfere with
									other
									people’s use.</li>
								<li clause="6.1.8">not modify, copy, adapt, create derivative works, reproduce,
									disassemble, decompile,
									reverse engineer or extract the source code of any part of our Services.</li>
								<li clause="6.1.9">not transmit any viruses, malware, or other types of malicious
									software through any
									Services.</li>
								<li clause="6.1.10">not engage in abusive or excessive usage of any Services, which is
									usage
									significantly
									in excess of average usage patterns that adversely affects the speed,
									responsiveness,
									stability, availability, or functionality of the Services for other users. We will
									endeavour to notify you of any abusive or excessive usage to provide you with an
									opportunity to reduce such usage to a level acceptable by us.</li>
								<li clause="6.1.11">not use our Services to infringe the Intellectual Property Rights of
									others, or to
									commit any unlawful activity.</li>
								<li clause="6.1.12">agree that if your use of the Services requires you to comply with
									industry-specific
									regulations applicable to such use, you will be solely responsible for such
									compliance,
									unless we have agreed in writing with you otherwise. You may not use the Services in
									a
									way that would subject us to those industry-specific regulations without obtaining
									our
									prior written agreement. </li>
								<li clause="6.1.13">not register accounts by “bots” or other automated methods.</li>
								<li clause="6.1.14">not resell or make available any of our Services to any third party,
									or otherwise
									commercially exploit our Services.</li>
							</ol>
						</li>
						<li clause="6.2">When accessing our Services, you and (where relevant) your personnel must:
							<ol>
								<li clause="6.2.1">not impersonate another person or misrepresent authorisation to act
									on behalf of
									others or us;</li>
								<li clause="6.2.2">correctly identify the sender of all electronic transmissions;</li>
								<li clause="6.2.3">not attempt to undermine the security or integrity of our Underlying
									Systems; </li>
								<li clause="6.2.4">not use, or misuse, the Service in any way which may impair the
									functionality of the
									Underlying Systems or impair the ability of any other user to use the Service;</li>
								<li clause="6.2.5">not attempt to view, access or copy any material or Data other than:
								</li>
								<li clause="6.2.6">that which you are authorised to access; and</li>
								<li clause="6.2.7">to the extent necessary for you to use the Service in accordance with
									these Terms;
									and</li>
								<li clause="6.2.8">neither use the Service in a manner, nor transmit, input or store any
									Data, that
									breaches any third party right (including Intellectual Property Rights and privacy
									rights) or is Objectionable, incorrect or misleading.</li>
							</ol>
						</li>
						<li clause="6.3">Without limiting clause 6.2, no individual other than a Permitted User may
							access or use the
							Service. You may authorise any member of your personnel to be a Permitted User, in which
							case you must provide us with the Permitted User’s name and other information that we
							reasonably require in relation to the Permitted User. You must procure each Permitted User’s
							compliance with clauses 6.1 and 6.2 and any other reasonable condition notified by us to
							you.</li>
						<li clause="6.4">A breach of any of these Terms by your personnel (including, to avoid doubt, a
							Permitted
							User) is deemed to be a breach of these Terms by you.</li>
						<li clause="6.5">You are responsible for procuring all licences, authorisations and consents
							required for you
							and your personnel to use the Services, including to use, store and input Data into, and
							process and distribute Data through, any Service. For avoidance of doubt this includes
							ensuring that you obtain and maintain any required agreements with Feed Providers
							you have engaged and which you instruct us that you wish to make use of through
							the Cassini CTI Service.</li>
						<li clause="6.6">You must promptly follow any reasonable instruction we give you in respect of
							your use of the Services. </li>
					</ol>
				</li>
				<li clause="7">DATA
					<ol>
						<li clause="7.1">You acknowledge that:
							<ol>
								<li clause="7.1.1">we may require access to the Data to exercise our rights and perform
									our obligations
									under these Terms; and</li>
								<li clause="7.1.2">to the extent that this is necessary but subject to clause 10, we may
									authorise a
									member or members of our personnel to access the Data for this purpose. </li>
							</ol>
						</li>
						<li clause="7.2">You must arrange all consents and approvals that are necessary for us to access
							the Data as
							described in clause 7.1.</li>
						<li clause="7.3">You acknowledge and agree that:
							<ol>
								<li clause="7.3.1">we may:
									<ul>
										<li class="alpha">use Data and information about your and your end users’ use of
											our Services to generate anonymised and aggregated statistical and
											analytical data (<span class="term">Analytical Data</span>); and</li>
										<li class="alpha">use Analytical Data for: our internal research and product
											development purposes, to conduct statistical analysis, identify trends and
											insights, develop and improve our products and services, analyse and gain
											understanding as to how our Services are being used;</li>
										<li class="alpha">use Analytical Data to market our Services; and</li>
										<li class="alpha">generate and distribute reports or insights and other
											materials to third parties and
											other users of our Services which may contain Analytical Data or be
											generated from your Data.</li>
									</ul>
								</li>
								<li clause="7.3.2">our rights under clause 7.3.1 above will survive termination of
									expiry of the
									Agreement; and </li>
								<li clause="7.3.3">title to, and all Intellectual Property Rights in, Analytical Data is
									and remains
									our property.</li>
							</ol>
						</li>
						<li clause="7.4">You acknowledge and agree that to the extent Data contains personal
							information, in
							collecting, holding and processing that information through the Service, we are acting as
							your agent for the purposes of the Privacy Act 1993 and any other applicable privacy law.
							You must obtain all necessary consents from the relevant individual to enable us to collect,
							use, hold, disclose and process that information in accordance with these Terms.</li>
						<li clause="7.5">While we will take standard industry measures to back up all Data stored using
							the Services,
							you agree to keep a separate back-up copy of all Data uploaded by you onto the Services.
						</li>
						<li clause="7.6">You agree that we may store Data (including any personal information) in secure
							servers in
							Australia and may access that Data (including any personal information) in Australia and New
							Zealand from time to time. </li>
						<li clause="7.7">Where we think there has been unauthorised access to Data or personal
							information, we’ll let
							you know and give you information about what has happened. Depending on the nature of the
							unauthorised access, and the location of the Data or personal information, you may be
							required to assess whether the unauthorised access must be reported to the person identified
							by the personal information and/or a relevant authority. We think you're best placed to make
							this decision, because you’ll have the most knowledge about the personal information stored
							in the Data.</li>
						<li clause="7.8">For the avoidance of doubt, unless otherwise consented to by you in writing, we
							will only disclose Analytical Data externally in a
							de-identified (anonymous) form that does not identify you or your Permitted Users, and that
							is stripped of all persistent identifiers using industry standard processes. You are not
							responsible for our use of Analytical Data.</li>
					</ol>
				</li>
				<li clause="8">FEES
					<ol>
						<li clause="8.1">We will provide you with valid GST tax invoices on a monthly basis prior to the
							due date for
							payment. </li>
						<li clause="8.2">The Fees exclude GST, which you must pay on taxable supplies.</li>
						<li clause="8.3">You must pay the Fees:
							<ol>
								<li clause="8.3.1">by the 20th of the month following the date of invoice; and</li>
								<li clause="8.3.2">electronically in cleared funds without any set off or deduction.
								</li>
							</ol>
						</li>
						<li clause="8.4">We may charge interest on overdue amounts. Interest will be calculated from the
							due date to
							the date of payment (both inclusive) at an annual percentage rate equal to the corporate
							overdraft reference rate (monthly charging cycle) applied by our primary trading bank as at
							the due date (or, if our primary trading bank ceases to quote that rate, then the rate which
							in the opinion of the bank is equivalent to that rate in respect of similar overdraft
							accommodation expressed as a percentage) plus 2% per annum.</li>
						<li clause="8.5">We may increase or otherwise vary the Fees at any time. If we are already
							providing Services
							to you, we will give at least 30 days’ notice of a Fee increase. If you do not wish to pay
							the increased Fees, you may terminate these Terms and your right to access and use the
							Services on no less than 10 days’ notice, provided the notice is received by us before the
							effective date of the Fee increase. If you do not terminate these Terms and your right to
							access and use the Service in accordance with this clause, you are deemed to have accepted
							the increased Fees. </li>
					</ol>
				</li>
				<li clause="9">INTELLECTUAL PROPERTY
					<ol>
						<li clause="9.1">Subject to clause 9.2, title to, and all Intellectual Property Rights in or
							arising out of
							our Services, the Website, and all Underlying Systems is and remains our property (and our
							licensors’ property). You must not contest or dispute that ownership, or the validity of
							those Intellectual Property Rights.</li>
						<li clause="9.2">Title to, and all Intellectual Property Rights in, the Data (as between the
							parties) remains
							your property. You grant us a worldwide, non-exclusive, fully paid up, transferable,
							irrevocable licence to use, store, copy, modify, make available and communicate the Data and
							any other of your Intellectual Property used by us or necessary for us to deliver the
							Service for our own business purposes including any purpose in connection with the exercise
							of our rights and performance of our obligations in accordance with these Terms. </li>
						<li clause="9.3">To the extent not owned by us, you grant us a royalty-free, transferable,
							irrevocable and
							perpetual licence to use for our own business purposes any know-how, techniques, ideas,
							methodologies, and similar Intellectual Property used by us in the provision of the
							Services.</li>
						<li clause="9.4">If you provide us with ideas, comments or suggestions relating to our
							Intellectual Property (including the Service or Underlying Systems) (together feedback):
							<ol>
								<li clause="9.4.1">all Intellectual Property Rights in that feedback, and anything
									created as a result
									of that feedback (including new material, enhancements, modifications or derivative
									works), are owned solely by us; and</li>
								<li clause="9.4.2">we may use or disclose the feedback for any purpose.</li>
							</ol>
						</li>
						<li clause="9.5">If, in performing Development and Support Services, we produce any Deliverables
							we grant you
							a worldwide, non-exclusive, fully paid up, transferable, irrevocable licence to use, store,
							copy, modify, make available and communicate those Deliverables solely in connection with
							your use of our Cassini CTI Service in accordance with these Terms.</li>
						<li clause="9.6">You acknowledge that the Service may link to third party websites or feeds that
							are
							connected or relevant to the Service. Any link from the Service does not imply that we
							endorse, approve or recommend, or have responsibility for, those websites or feeds or their
							content or operators. To the maximum extent permitted by law, we exclude all responsibility
							or liability for those websites or feeds.</li>
						<li clause="9.7">You agree to, at your own cost, promptly do anything reasonably required by us
							to give
							effect to the intellectual property provisions of these Terms.</li>
					</ol>
				</li>
				<li clause="10">CONFIDENTIALITY
					<ol>
						<li clause="10.1">Each party must, unless it has the prior written consent of the other party:
							<ol>
								<li clause="10.1.1">keep confidential at all times the Confidential Information of the
									other party;
								</li>
								<li clause="10.1.2">effect and maintain adequate security measures to safeguard the
									other party’s
									Confidential Information from unauthorised access or use; and</li>
								<li clause="10.1.3">disclose the other party’s Confidential Information to its personnel
									or professional
									advisors on a need to know basis only and, in that case, ensure that any personnel
									or professional advisor to whom it discloses the other party’s Confidential
									Information is aware of, and complies with, clauses 10.1.1 and 10.1.2.</li>
							</ol>
						</li>
						<li clause="10.2">The obligation of confidentiality in clause 10.1 does not apply to any
							disclosure or use of
							Confidential Information:
							<ol>
								<li clause="10.2.1">to the extent we disclose Telemetry to Feed Providers or other 
									third parties in connection with delivering the Services;</li>
								<li clause="10.2.2">for the purpose of performing a party’s obligations, or exercising a
									party’s rights, under these Terms;</li>
								<li clause="10.2.3">required by law (including under the rules of any stock exchange);
								</li>
								<li clause="10.2.4">which is publicly available through no fault of the recipient of the
									Confidential
									Information or its personnel;</li>
								<li clause="10.2.5">which was rightfully received by a party from a third party without
									restriction and
									without breach of any obligation of confidentiality; or</li>
								<li clause="10.2.6">by us if required as part of a bona fide sale of our business
									(assets or shares,
									whether in whole or in part) to a third party, provided that we enter into a
									confidentiality agreement with the third party on terms no less restrictive than
									this clause 10.</li>
							</ol>
						</li>
					</ol>
				</li>
				<li clause="11">WARRANTIES
					<ol>
						<li clause="11.1">Each party warrants that it has full power and authority to enter into, and
							perform its
							obligations under, these Terms.</li>
						<li clause="11.2">To the maximum extent permitted by law our warranties are limited to those set
							out in these
							Terms, and otherwise:
							<ol>
								<li clause="11.2.1">WE GIVE NO WARRANTIES, EXPRESS OR IMPLIED, AND NO GUARANTEES IN
									RESPECT OF THE
									SERVICES OR YOUR USE OF THE SERVICE. IN PARTICULAR WE DO NOT WARRANT OR GUARANTEE
									THE AVAILABILITY OR FITNESS OF ANY SERVICE OR DELIVERABLE FOR ANY PURPOSE. YOUR USE
									OF THE SERVICES AND DELIVERABLES IS AT YOUR OWN RISK AND THE SERVICES AND
									DELIVERABLES ARE PROVIDED ON AN “AS IS” BASIS.</li>
								<li clause="11.2.2">our warranties are limited to those set out in these Terms, and all
									other
									conditions, guarantees or warranties whether expressed or implied by statute or
									otherwise (including any warranty under Part 3 of the Contract and Commercial Law
									Act 2017) are expressly excluded and, to the extent that they cannot be excluded,
									liability for them is limited to NZD1,000.00; and</li>
								<li clause="11.2.3">we make no representation concerning the quality of the Services and
									do not promise
									that the Services will be secure, free of viruses or other harmful code,
									uninterrupted or error free, meet your requirements or be suitable for any
									particular purpose (including that the use of the Services will fulfil or meet any
									statutory role or responsibility you may have).</li>
							</ol>
						</li>
						<li clause="11.3">You agree and represent that you are acquiring the
							Services, and accepting these Terms, for the purpose of trade. The parties agree
							that:
							<ol>
								<li clause="11.3.1">to the maximum extent permissible by law, the Consumer Guarantees
									Act
									1993 and Fair Trading Act 1986 any other applicable consumer protection
									legislation does not apply to the supply of the Service or these Terms;
									and</li>
								<li clause="11.3.2">it is fair and reasonable that the parties are bound by this clause
									11.3.</li>
							</ol>
						</li>
						<li clause="11.4">Where legislation or rule of law implies into these Terms a condition or
							warranty
							that cannot be excluded or modified by contract, the condition or warranty is deemed
							to be included in these Terms. However, our liability for any breach of that
							condition or warranty is limited, at our option, to:
							<ol>
								<li clause="11.4.1">supplying the Services again; and/or</li>
								<li clause="11.4.2">paying the costs of having the Services supplied again. </li>
							</ol>
						</li>
					</ol>
				</li>
				<li clause="12">LIABILITY AND INDEMNITY
					<ol>
						<li clause="12.1">If you have any basis for recovering damages or other costs or expenses from
							us, our
							maximum aggregate liability under or in connection with these Terms or relating to
							the Services, whether in contract, tort (including negligence), breach of statutory
							duty or otherwise, will not in any Year exceed an amount equal to the Fees paid by
							you relating to the Service in the previous Year (which in the first Year is deemed
							to be the total Fees paid by you from the Start Date to the date of the first event
							giving rise to liability). The cap in this clause 12.1 includes the cap set out in
							clause 11.2.2.</li>
						<li clause="12.2">We are not liable to you under or in connection with these Terms or the
							Services for
							any:
							<ol>
								<li clause="12.2.1">loss of profit, revenue, savings, business, capital, anticipated
									savings,
									use, data (including Data), and/or goodwill; or</li>
								<li clause="12.2.2">consequential, indirect, incidental, punitive, exemplary, special
									loss,
									special damage or loss of any kind.</li>
							</ol>
						</li>
						<li clause="12.3">Clauses 12.1 and 12.2 do not apply to limit our liability under or in
							connection
							with these Terms for:
							<ol>
								<li clause="12.3.1">personal injury or death;</li>
								<li clause="12.3.2">fraud or wilful misconduct; or </li>
								<li clause="12.3.3">a breach of clause 10.</li>
							</ol>
						</li>
						<li clause="12.4">Neither party will be responsible, liable, or held to be in breach of these
							Terms
							for any failure to perform its obligations under these Terms or otherwise, to the
							extent that the failure is caused by the other party failing to comply with its
							obligations under these Terms, or by the negligence or misconduct of the other party
							or its personnel.</li>
						<li clause="12.5">Each party must take reasonable steps to mitigate any loss or damage, cost or
							expense it may suffer or incur arising out of anything done or not done by the other
							party under or in connection with these Terms or the Service.</li>
						<li clause="12.6">You agree to indemnify us against and hold us harmless from:
							<ol>
								<li clause="12.6.1">any action or claim or demand made or threatened against us by any third party; and</li>
								<li clause="12.6.2">any loss, damage or cost or expense (including legal expenses), suffered, awarded, paid, or incurred by us,</li>
								arising from or in relation to:
								<li clause="12.6.3">our provision of Services to you;</li>
								<li clause="12.6.4">your use of our Services; and/or</li>
								<li clause="12.6.5">any Data in the Services.</li>
							</ol>
						</li>
					</ol>
				</li>
				<li clause="13">TERM, TERMINATION AND SUSPENSION
					<ol>
						<li clause="13.1">Unless terminated under this clause 13, these Terms and your right to access
							and use
							the Services:
							<ol>
								<li clause="13.1.1">starts on the Start Date; and</li>
								<li clause="13.1.2">continues until a party gives at least 30 days’ notice that these
									Terms and
									your access to and use of the Service will terminate on the expiry of that
									notice. </li>
							</ol>
						</li>
						<li clause="13.2">Subject to clause 8.6, if the subscription option you have selected includes a
							minimum initial term, the earliest date for termination under clause 13.1 will be
							the expiry of that initial term.</li>
						<li clause="13.3">Where you are engaging Development and Support Services under a Statement of
							Work,
							if you terminate those Development and Support Services in accordance with clause
							13.1.2 you must pay the total (or balance remaining) of the Fees applicable to those
							Development and Support Services, as set out in the relevant Statement of Work, on
							termination.</li>
						<li clause="13.4">Either party may, by notice to the other party, immediately terminate these
							Terms
							and your right to access and use the Service if the other party:
							<ol>
								<li clause="13.4.1">breaches any material provision of these Terms and the breach is
									not:
									<ul>
										<li class="alpha">remedied within 10 days of the receipt of a notice
											from the first party requiring it to remedy the breach; or</li>
										<li class="alpha">capable of being remedied; or</li>
									</ul>
								</li>
								<li clause="13.4.2">becomes insolvent, liquidated or bankrupt, has an administrator,
									receiver,
									liquidator, statutory manager, mortgagee’s or chargee’s agent appointed,
									becomes subject to any form of insolvency action or external administration,
									or ceases to continue business for any reason.</li>
							</ol>
						</li>
						<li clause="13.5">You may terminate these Terms and your right to access and use the Services in
							accordance with clause 8.6.</li>
						<li clause="13.6">We may terminate your access and use of our Services, or any part of them, at
							any
							time by notice to you in writing.</li>
						<li clause="13.7">Termination of these Terms does not affect either party’s rights and
							obligations
							that accrued before that termination.</li>
						<li clause="13.8">On termination of these Terms, you must pay all Fees for the provision of the
							Services prior to that termination.</li>
						<li clause="13.9">No compensation is payable by us to you as a result of termination of these
							Terms
							for whatever reason, and you will not be entitled to a refund of any Fees that you
							have already paid.</li>
						<li clause="13.10">Except to the extent that a party has ongoing rights to use Confidential
							Information, at the other party’s request following termination of these Terms but
							subject to clause 12.9, a party must promptly return to the other party or destroy
							all Confidential Information of the other party that is in the first party’s
							possession or control. </li>
						<li clause="13.11">At any time prior to one month after the date of termination, you may
							request:
							<ol>
								<li clause="13.11.1">a copy of any Data stored using the Service, provided you pay our
									reasonable
									costs of supplying that copy. On receipt of that request, we must provide a
									copy of the Data in a common electronic form. We do not warrant that the
									format of the Data will be compatible with any software; and/or</li>
								<li clause="13.11.2">deletion of the Data stored using the Services, in which case we
									must use
									reasonable efforts to promptly delete that Data. </li>
							</ol>							
						</li>
						To avoid doubt, we are not required to comply with clause 13.11.1 to the extent that
						you have previously requested deletion of the Data.
						<li clause="13.12">Without limiting any other right or remedy available to us, we may restrict
							or
							suspend your access to and use of the Services and/or delete, edit or remove the
							relevant Data if we consider that you, any of your personnel, or any other party at
							your direction have:
							<ol>
								<li clause="13.12.1">undermined, or attempted to undermine, the security or integrity of
									the
									Services or any Underlying Systems;</li>
								<li clause="13.12.2">used, or attempted to use, the Services:
									<ul>
										<li class="alpha">for improper purposes; or</li>
										<li class="alpha">in a manner, other than for normal operational
											purposes, that materially reduces the operational performance of the
											Service;</li>
										<li class="alpha">transmitted, inputted or stored any Data that breaches
											or may breach these Terms or any third party right (including
											Intellectual Property Rights and privacy rights), or that is or may
											be Objectionable, incorrect or misleading; or</li>
										<li class="alpha">performed, or directed to be performed, any level of
											security testing without our prior written consent; or</li>
										<li class="alpha">otherwise materially breached these Terms.</li>
									</ul>
								</li>
							</ol>
						</li>
					</ol>
				</li>
				<li clause="14">GENERAL
					<ol>
						<li clause="14.1">Neither party is liable to the other for any failure to perform its
							obligations
							under these Terms to the extent caused by Force Majeure.</li>
						<li clause="14.2">You grant us the right to use your company name and logo as a reference for
							marketing or promotional purposes on our website and in other public or private
							communications with our existing or potential customers, subject to your standard
							trademark usage guidelines as provided to us from time-to-time. </li>
						<li clause="14.3">No person other than you and us has any right to a benefit under, or to
							enforce,
							these Terms.</li>
						<li clause="14.4">For us to waive a right under these Terms, that waiver must be in writing and
							signed
							by us.</li>
						<li clause="14.5">Subject to clause 7.4, we are your independent contractor, and no other
							relationship
							(e.g. joint venture, agency, trust or partnership) exists under these Terms.</li>
						<li clause="14.6">If we need to contact you, we may do so by email or by posting a notice on the
							Website. You agree that this satisfies all legal requirements in relation to written
							communications. You may give notice to us under or in connection with these Terms by
							emailing info@cassini.nz.</li>
						<li clause="14.7">These Terms, and any dispute relating to these Terms or the Service, are
							governed by
							and must be interpreted in accordance with the laws of New Zealand. Each party
							submits to the non-exclusive jurisdiction of the Courts of New Zealand in relation
							to any dispute connected with these Terms or the Service.</li>
						<li clause="14.8">Clauses which, by their nature, are intended to survive termination of these
							Terms, including this clause and clauses 9, 10, 12, 13.6 to 13.11, and 14.6 continue
							in force. </li>
						<li clause="14.9">If any part or provision of these Terms is or becomes illegal, unenforceable,
							or
							invalid, that part or provision is deemed to be modified to the extent required to
							remedy the illegality, unenforceability or invalidity. If modification is not
							possible, the part or provision must be treated for all purposes as severed from
							these Terms. The remainder of these Terms will be binding on you.</li>
						<li clause="14.10">Subject to clauses 2.1 and 8.6, any variation to these Terms must be in
							writing and
							signed by both parties.</li>
						<li clause="14.11">These Terms set out everything agreed by the parties relating to the
							Services, and
							supersede and cancel anything discussed, exchanged or agreed prior to the Start
							Date. The parties have not relied on any representation, warranty or agreement
							relating to the Services that is not expressly set out in these Terms, and no such
							representation, warranty or agreement has any effect from the Start Date. Without
							limiting the previous sentence, the parties agree to contract out of sections 9, 12A
							and 13 of the Fair Trading Act 1986, and that it is fair and reasonable that the
							parties are bound by this clause 14.10.</li>
						<li clause="14.12">You may not assign, novate, subcontract or transfer any right or obligation
							under
							these Terms without our prior written consent, that consent not to be unreasonably
							withheld. You remain liable for your obligations under these Terms despite any
							approved assignment, subcontracting or transfer. </li>
					</ol>
				</li>
			</ol>
			<p>You’ve made it to the end. Thanks for reading our terms!</p>
		</div>
	</section>
</div>