import { Component, SecurityContext } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ContactUsApiService } from 'src/app/service/contactus.service';
import { Router } from '@angular/router';
import { DomSanitizer, Title } from '@angular/platform-browser';

@Component({
    selector: 'cassini-cti-app',
    templateUrl: './contact.html',
    styleUrls: ['./contact.scss'],
})
export class ContactComponent {
    contactForm = new UntypedFormGroup({
        subjectCtrl: new UntypedFormControl('', [
            Validators.required
        ]),
        bodyCtrl: new UntypedFormControl('', [
            Validators.required
        ]),
    });

    get subjectCtrl() { return this.contactForm.get('subjectCtrl') }
    get bodyCtrl() { return this.contactForm.get('bodyCtrl') }

    constructor(
        private titleService: Title,
        private contactUsApi: ContactUsApiService,
        public router: Router,
        private sanitizer: DomSanitizer) {
            this.titleService.setTitle("Cassini CTI | Contact Us");
    }

    submitContact() {
        if (this.contactForm.valid) {
            this.contactUsApi.postContactEnquiry(
                this.sanitizer.sanitize(SecurityContext.HTML, this.subjectCtrl.value), 
                this.sanitizer.sanitize(SecurityContext.HTML, this.bodyCtrl.value)
            ).subscribe(res => {
                this.router.navigate(['/contact-success']);
            });
        }
    }
}

