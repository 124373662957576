import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'cassini-cti-app',
    template: `
        <h2 matDialogTitle>An error has occurred</h2>
        <mat-dialog-content>
            <p>Unfortunately an error has occurred.</p>
            <p>Please retry the action and if this error persists contact <a href="mailto:{{supportEmail}}?subject=Error+Report&body={{ getBody() }}">support</a> for assistance.</p>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-raised-button class="btn btn-success" matDialogClose>Close</button>
        </mat-dialog-actions>
    `
})

export class ErrorDialogComponent {
    public errorMessage: string;
    public supportEmail = environment.support_email_address;

    constructor(public dialogRef: MatDialogRef<ErrorDialogComponent>) {
    }

    public getBody() {
        return encodeURIComponent(`
            <<Please insert your customer details and a description of what you were attempting to do here>>
            \n\n
            -----------\n
            Error Details:\n
            ` + this.errorMessage);   
    }
}
