import {Component, OnDestroy, OnInit} from "@angular/core";
import { Auth } from 'aws-amplify';
import { Router } from '@angular/router';
import { Meta, Title } from "@angular/platform-browser";


@Component({
    selector: 'cassini-cti-app',
    templateUrl: './why-cassini.html'
})
export class WhyCassiniComponent implements OnInit, OnDestroy {

    constructor(
        private titleService: Title,
        private metaService: Meta,
        public router: Router) 
    {
        const description = 'Why you should choose Cassini CTI for your integration with Malware Free Networks and the CERT NZ phishing feed.';

        this.titleService.setTitle("Cassini CTI | Why choose Cassini CTI?");
        this.metaService.removeTag('name=description');
        this.metaService.addTags([
            { name: 'description', content: description },
            { name: 'robots', content: 'index,follow' },
            { property: 'og:title', content: 'Why choose Cassini CTI for your MFN and CERT NZ phishing integration.' },
            { property: 'og:type', content: 'article' },
            { property: 'og:image', content: location.origin + '/assets/img/cassini_logo_stacked.png' },
            { property: 'og:url', content: location.origin + this.router.url },
            { property: 'og:description', content: description }
        ]);

    }

    ngOnInit() {
        Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(
            // User is authenticated
            user => {
                this.router.navigate(['/portal']);
            },
            result => {}
        ); 
    }

    ngOnDestroy() {
        this.metaService.removeTag('property="og:title"');
        this.metaService.removeTag('property="og:type"');
        this.metaService.removeTag('property="og:image"');
        this.metaService.removeTag('property="og:url"');
        this.metaService.removeTag('property="og:description"');
    }
}


