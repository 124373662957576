import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { AmplifyService } from 'aws-amplify-angular';
import { Auth } from 'aws-amplify';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SessionExpiredDialogComponent } from "./session-expired.component";
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'cassini-cti-app',
    templateUrl: './login.html',
    styleUrls: ['./login.scss']
})
export class LoginComponent implements OnInit {
    email: string;
    newPassword: string;
    password: string;
    errorMessage: string;
    qrcode = "";
    mfaSetup = false;
    setNewPassword = false;
    invalidCredentials = false;
    invalidCode = false;
    mfaChallenge = false;
    signedIn: boolean;
    user: any;
    greeting: string;

    public displayLogoutNav: boolean;
    sessionExpiredDialogRef: MatDialogRef<SessionExpiredDialogComponent>;


    @ViewChild('mfaCode') mfaChallengeInput: ElementRef;

    public constructor(
        public router: Router,
        private renderer: Renderer2,
        private amplifyService: AmplifyService,
        public dialog: MatDialog
    ) { }

    ngOnInit() {
        this.errorMessage = null;
    }

    async signIn() {
        // ensure the email and password have not spaces included and the email address is lower case
        this.email = this.email.trim().toLowerCase()
        this.password = this.password.trim()
        if (this.email == null || this.password == null) {
            this.errorMessage = "All fields are required";
            return;
        }
        this.errorMessage = null;

        try {
            const user = await Auth.signIn(this.email, this.password);
            if (user.challengeName === 'SMS_MFA' ||
                user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                // You need to get the code from the UI inputs
                // and then trigger the following function with a button click
                this.mfaChallenge = true;
                let verificationCodeSubscripton = fromEvent(this.mfaChallengeInput.nativeElement, 'keyup')
                    .pipe(debounceTime(1200)).subscribe(c => {
                        // wait until we have at least 6 characters
                        if (this.mfaChallengeInput.nativeElement.value.length >= 6) {
                            // If MFA is enabled, sign-in should be confirmed with the confirmation code
                            Auth.confirmSignIn(
                                user,   // Return object from Auth.signIn()
                                this.mfaChallengeInput.nativeElement.value,   // Confirmation code  
                                user.challengeName // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA                        
                            ).then(result => {
                                // This is a hacky work around to get the navigation to change as the 
                                // "this.amplifyService.authStateChange$" event never fires the "signedIn" value.
                                this.amplifyService.setAuthState({ state: 'signedIn', user: user });
                                // Send the signed in user to the portal home page
                                this.router.navigate(['/portal/']);
                            }).catch(err => {
                                if (err.code === 'NotAuthorizedException' && err.message === "Invalid session for the user, session is expired.") {
                                    // This error can occur if the user was too slow entering the verification code.
                                    const dialogConfig = new MatDialogConfig();

                                    dialogConfig.disableClose = true;
                                    dialogConfig.autoFocus = true;
                                    dialogConfig.position = {
                                        'top': '300px',
                                    };

                                    this.sessionExpiredDialogRef = this.dialog.open(SessionExpiredDialogComponent, dialogConfig);
                                    this.sessionExpiredDialogRef.afterClosed().subscribe(result => {
                                        this.sessionExpiredDialogRef = null;
                                        // clear the verification code input 
                                        this.mfaChallengeInput.nativeElement.value = ""
                                        // change the state of the form so the user is presented with the login controls again
                                        this.mfaChallenge = false;
                                    });
                                    // remove the subscription so this event won't be called with the invalid user session
                                    // the second time the user logs in.
                                    verificationCodeSubscripton.unsubscribe();
                                } else if (err.code === 'CodeMismatchException' && err.message === "Invalid code received for user") {
                                    this.invalidCode = true
                                }
                            });
                        }
                    }
                    );
            } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                if (!this.setNewPassword) {
                    this.setNewPassword = true;
                } else {
                    await Auth.completeNewPassword(
                        user,                       // the Cognito User Object
                        this.newPassword.trim(),    // the new password - ensure no whitespace included
                        {}
                    ).then(result => {
                        // Send the signed in user to the portal home page
                        this.router.navigate(['/portal/']);
                    }).catch(err => {
                        if (err.code === "InvalidPasswordException") {
                            this.errorMessage = err.message;
                        } else if (err.message === "Attempt limit exceeded, please try after some time.") {
                            this.errorMessage = err.message;
                        }
                    }); 
                }
            } else if (user.challengeName === 'MFA_SETUP') {
                // This happens when the MFA method is TOTP
                // The user needs to setup the TOTP before using it
                // More info please check the Enabling MFA part               
                // To setup TOTP, first you need to get a `authorization code` from Amazon Cognito
                // `user` is the current Authenticated user
                Auth.setupTOTP(user).then((code) => {
                    const environment_prefix = environment.envName == "production" ? "" : environment.envName;
                    this.qrcode = "otpauth://totp/" + this.email + "?secret=" + code + "&issuer=" + environment_prefix + "cassini.nz";
                    this.mfaSetup = true;
                    this.mfaChallenge = true;
                    fromEvent(this.mfaChallengeInput.nativeElement, 'keyup')
                        .pipe(debounceTime(1200)).subscribe(c => {
                            // wait until we have at least 6 characters
                            if (this.mfaChallengeInput.nativeElement.value.length >= 6) {
                                // Then you will have your TOTP account in your TOTP-generating app (like Google Authenticator)
                                // Use the generated one-time password to verify the setup
                                Auth.verifyTotpToken(user, this.mfaChallengeInput.nativeElement.value).then(() => {
                                    // Set TOTP as the preferred MFA method
                                    Auth.setPreferredMFA(user, 'TOTP');

                                    // This is a hacky work around to get the navigation to change as the 
                                    // "this.amplifyService.authStateChange$" event never fires the "signedIn" value.
                                    this.amplifyService.setAuthState({ state: 'signedIn', user: user });

                                    // Send the signed in user to the portal home page
                                    this.router.navigate(['/portal/']);
                                }).catch(e => {
                                    this.invalidCode = true;
                                    console.error(e);
                                });
                            }
                        }
                        );
                });

            } else {
                // The user directly signs in
                this.router.navigate(['/portal/']);
            }
        } catch (err) {
            if (err.code === 'UserNotConfirmedException') {
                // The error happens if the user didn't finish the confirmation step when signing up
                // In this case you need to resend the code and confirm the user
                // About how to resend the code and confirm the user, please check the signUp part

                this.router.navigate(['/confirmRegistration', this.email]);

            } else if (err.code === 'PasswordResetRequiredException') {
                // The error happens when the password is reset in the Cognito console
                // In this case you need to call forgotPassword to reset the password
                // Please check the Forgot Password part.

                console.log("PasswordResetRequiredException!!!");

            } else if (err.code === 'NotAuthorizedException') {
                // The error happens when the incorrect password is provided
                this.invalidCredentials = true;
            } else if (err.code === 'UserNotFoundException') {
                // The error happens when the supplied username/email does not exist in the Cognito user pool
                this.invalidCredentials = true;
            }
        }
    }
}
