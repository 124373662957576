<h2>Endpoint Details</h2>
<p>
    View and manage your currently allocated Endpoint credentials on this page.
</p>

<mat-progress-spinner [class.hide-loading]="!loading" [mode]="'indeterminate'" [color]="'accent'" class="spinner">
</mat-progress-spinner>
<div class="container" [class.hide-loading]="loading">
    <div class="mat-elevation-z8">
        <mat-table #table [dataSource]="dataSource" matSort matSortActive="created" matSortDirection="desc"
            matSortDisableClear>

            <ng-container matColumnDef="created">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Created </mat-header-cell>
                <mat-cell *matCellDef="let endpoint"> {{endpoint.created | date:'short' }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
                <mat-cell *matCellDef="let endpoint"> {{endpoint.name}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
                <mat-cell *matCellDef="let endpoint"> {{endpoint.description}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="lastActivity">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Last Activity </mat-header-cell>
                <mat-cell *matCellDef="let endpoint"> {{endpoint.last_accessed | date:'short' }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="delete">
                <mat-header-cell *matHeaderCellDef> Delete </mat-header-cell>
                <mat-cell *matCellDef="let endpoint">
                    <mat-icon (click)="deleteEndpoint($event, endpoint)">delete_forever</mat-icon>
                </mat-cell>
            </ng-container>

            <!-- This is the expanded content column - The detail row is made up of this one column. -->
            <ng-container matColumnDef="expandedDetail">
                <mat-cell *matCellDef="let row">
                    <table class="endpoint_details">
                        <tr>
                            <td>
                                <mat-label>API Key:&nbsp;</mat-label>
                            </td>
                            <td>                                                            
                                <div *ngIf="row.detail.apikey_value === null; else apikeyplaceholder">
                                </div>
                                <ng-template #apikeyplaceholder>
                                    <ng-container *ngIf="(currentUser && currentUser.isAdmin); else hiddenapikey">
                                        <mat-icon class="copy" aria-valuetext="copy" [copy-clipboard]="row.detail.apikey_value"
                                        (click)="notifyEndpointApikeyCopied($event)">content_copy</mat-icon><span class="copied">COPIED</span> {{ row.detail.apikey_value }}                                        
                                    </ng-container>                                    
                                </ng-template>
                                <ng-template #hiddenapikey>
                                    ••••••••••••••••••••••••••••••
                                </ng-template>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-label>Username:&nbsp;</mat-label>
                            </td>
                            <td>                                                            
                                <div *ngIf="row.detail.username === null; else usernameplaceholder">
                                </div>
                                <ng-template #usernameplaceholder>
                                    <ng-container *ngIf="(currentUser && currentUser.isAdmin); else hiddenusername">
                                        <mat-icon class="copy" aria-valuetext="copy" [copy-clipboard]="row.detail.username"
                                        (click)="notifyEndpointApikeyCopied($event)">content_copy</mat-icon><span class="copied">COPIED</span> {{ row.detail.username }}                                        
                                    </ng-container>                                    
                                </ng-template>
                                <ng-template #hiddenusername>
                                    ••••••••••••••••••••••••••••••
                                </ng-template>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-label>Password:&nbsp;</mat-label>
                            </td>                                
                            <td>
                                <div *ngIf="row.detail.password === null; else passwordplaceholder">
                                </div>
                                <ng-template #passwordplaceholder>
                                    <ng-container *ngIf="(currentUser && currentUser.isAdmin); else hiddenpassword">
                                        <mat-icon class="copy" aria-valuetext="copy" [copy-clipboard]="row.detail.password"
                                        (click)="notifyEndpointApikeyCopied($event)">content_copy</mat-icon><span class="copied">COPIED</span> {{ row.detail.password }}                                        
                                    </ng-container>                                    
                                </ng-template>
                                <ng-template #hiddenpassword>
                                    ••••••••••••••••••••••••••••••
                                </ng-template>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-label>Telemetry ID:&nbsp;</mat-label>
                            </td>                                
                            <td>
                                <div *ngIf="row.detail.telemetry_key === null; else telemetryidplaceholder">
                                </div>
                                <ng-template #telemetryidplaceholder>
                                    <ng-container *ngIf="(currentUser && currentUser.isAdmin); else hiddentelemetry">
                                        <mat-icon class="copy" aria-valuetext="copy" [copy-clipboard]="row.detail.telemetry_key"
                                        (click)="notifyEndpointApikeyCopied($event)">content_copy</mat-icon><span class="copied">COPIED</span> {{ row.detail.telemetry_key }}                                        
                                    </ng-container>                                    
                                </ng-template>
                                <ng-template #hiddentelemetry>
                                    ••••••••••••••••••••••••••••••
                                </ng-template>
                            </td>
                        </tr>
                    </table>
                </mat-cell>

            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns" matRipple class="element-row"
                [class.expanded]="isSelectedRow(row)" (click)="selectRow($event, row)"></mat-row>
            <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow"
                [@detailExpand]="isSelectedRow(row.detail) ? 'expanded' : 'collapsed'" style="overflow: hidden;">
            </mat-row>
        </mat-table>
    </div>
    <mat-paginator [pageSizeOptions]="[10, 20, 50]" [length]="endpointCount" [pageSize]="10" showFirstLastButtons>
    </mat-paginator>
    <p>
        <button *ngIf="currentUser && currentUser.isAdmin" type="button" mat-raised-button class="btn btn-success"
            (click)="addEndpoint()">Add New Endpoint</button>
    </p>
</div>

<!--           <mat-cell *matCellDef="let row">
                    {{ row.detail.description }}
                </mat-cell>

                <ng-container matColumnDef="username">
                    <mat-header-cell *matHeaderCellDef> Username </mat-header-cell>
                    <mat-cell *matCellDef="let endpoint">
                        <div *ngIf="(currentUser && currentUser.isAdmin); else usernameplaceholder">
                            {{ endpoint.username }} <mat-icon aria-valuetext="copy" [copy-clipboard]="endpoint.username"
                                (click)="notifyEndpointApikeyCopied($event)">content_copy</mat-icon><span>COPIED</span>
                        </div>
                        <ng-template #usernameplaceholder>
                            xxxxxxxxxxxxxxx
                        </ng-template>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="password">
                    <mat-header-cell *matHeaderCellDef> Password </mat-header-cell>
                    <mat-cell *matCellDef="let endpoint">
                        <div *ngIf="(currentUser && currentUser.isAdmin); else passwordplaceholder">
                            {{ getPassword(endpoint) }}
                            <mat-icon matSuffix (click)="endpoint.hide = !endpoint.hide">
                                {{endpoint.hide ? 'visibility_off' : 'visibility'}}
                            </mat-icon>
                            <mat-icon aria-valuetext="copy" [copy-clipboard]="endpoint.password"
                                (click)="notifyEndpointApikeyCopied($event)">content_copy</mat-icon><span>COPIED</span>
                        </div>
                        <ng-template #passwordplaceholder>
                            xxxxxxxxxxxxxxx
                        </ng-template>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="telemetry_key">
                    <mat-header-cell *matHeaderCellDef> Telemetry Key </mat-header-cell>
                    <mat-cell *matCellDef="let endpoint">
                        <div *ngIf="(currentUser && currentUser.isAdmin); else telemetrykeyplaceholder">
                            {{ endpoint.telemetry_key }} <mat-icon aria-valuetext="copy" [copy-clipboard]="endpoint.telemetry_key"
                                (click)="notifyEndpointApikeyCopied($event)">content_copy</mat-icon><span>COPIED</span>
                        </div>
                        <ng-template #telemetrykeyplaceholder>
                            xxxxxxxxxxxxxxx
                        </ng-template>
                    </mat-cell>
                </ng-container>
-->