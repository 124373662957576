import { Component } from "@angular/core";


@Component({
    selector: 'cassini-cti-app',
    templateUrl: './contact-success.html',
})
export class ContactSuccessComponent {
}

