import { Auth } from 'aws-amplify';
import { OnInit, OnDestroy, Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'cassini-cti-app',
    templateUrl: './forgot-password-step2.html'
})
export class ForgotPasswordStep2Component implements OnInit, OnDestroy {

    verificationCode: string;
    email: string;
    password: string;
    errorMessage: string;
    private sub: any;

    constructor(
        public router: Router,
        public route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.email = params['email'];
        });
        this.errorMessage = null;
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    onNext() {
        this.errorMessage = null;
        // Collect confirmation code and new password, then
        Auth.forgotPasswordSubmit(this.email, this.verificationCode, this.password)
            .then(data => {
                this.router.navigate(['/login']);
            })
            .catch(err => {
                if (err.code === "InvalidPasswordException") {
                    this.errorMessage = err.message;
                } else if (err.message === "Attempt limit exceeded, please try after some time.") {
                    this.errorMessage = err.message;
                }
            });
    }
}