<div class="content">
    <section class="page">
        <div class="container ">

            <form class="form-signin" role="form">
                <div class="form-group">
                    <h2>Forgot Password</h2>
                </div>                
                <div class="form-group">
                    <label class="control-label" for="verificationCode">Verification Code</label>
                    <input id="verificationCode" type="verificationCode" class="form-control"
                        [(ngModel)]="verificationCode" [ngModelOptions]="{standalone: true}">
                </div>
                <div class="form-group">
                    <label class="control-label" for="newPassword">New Password</label>
                    <input id="newPassword" type="password" class="form-control" length="40"
                        [(ngModel)]="password" [ngModelOptions]="{standalone: true}">
                    <small id="passwordHelp" class="form-text text-muted">Must include a number, upper, lower and special characters, and be at least 14 characters long.</small>
                </div>
                <div *ngIf="errorMessage!=null" class="alert alert-danger">
                    {{ errorMessage }}
                </div>
                <div class="form-group">
                    <button (click)="onNext()" id="signupSubmit" type="submit" class="btn btn-cassini-primary btn-block">
                        Reset Password
                    </button>
                </div>
            </form>

        </div>
    </section>
</div>