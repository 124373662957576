<div class="content">
	<section class="page">
		<div class="container">
			<img src="/assets/img/gcsb_logo.png" alt="GCSB logo" title="GCSB logo" class="about-mfn-logo" />
			<h2>What is Malware Free Networks (MFN)?</h2>
			<dl class="faq">

				<div>
					<dt>What is Malware Free Networks (MFN)?</dt>
					<dd>MFN is a threat detection and disruption service provided
						by the NCSC and provides near real-time threat intelligence reflecting current malicious
						activity
						targeting New Zealand organisations.</dd>
				</div>

				<div>
					<dt>What types of threats does MFN protect against?</dt>
					<dd>While MFN has been developed to help defend against malicious activity impacting
						nationally significant organisations, including corporates and government organisations,
						when used in combination with other feeds it can help defend against the full spectrum
						of cyber threats impacting New Zealand. Cassini pairs the MFN feed together with the CERT NZ
						Threat
						Intelligence feed to further increase the benefit to users. Cassini is also able to integrate
						both
						of these feeds into your existing security framework to uplift any current capabilities your
						organisation
						might already have deployed.</dd>
				</div>

				<div>
					<dt>What kinds of organisations is MFN designed for?</dt>
					<dd>MFN has been developed to help defend against malicious activity impacting small to medium
						enterprises, large corporates, and government organisations. MFN is not designed for residential
						customers. When MFN is used in combination with other feeds, it can help to defend against the
						full spectrum of cyber threats impacting New Zealand. Cassini pairs the MFN feed together with
						the CERT NZ Threat Intelligence feed to further increase the benefit to users. Cassini is also
						able to integrate both of these feeds into your existing security framework to uplift any
						current capabilities your organisation might already have deployed.</dd>
				</div>

				<div>
					<dt>How can I make use of MFN?</dt>
					<dd>The MFN threat intelligence service can be integrated with other systems and platforms
						to increase the range of malicious activity MFN customers are defended against. Cassini CTI is
						fully compliant with MFN and is able to provide a detection and disruption service to help
						organisations defend their networks against a broad range of high-impact malicious activity.
					</dd>
				</div>

				<div>
					<dt>Where do the indicators in the MFN feed come from?</dt>
					<dd>MFN is curated from a range of sources available to the NCSC. These include the NCSC’s
						international cyber security partners and information drawn from the NCSC’s cyber
						defence capabilities.</dd>
				</div>

				<div>
					<dt>Is MFN the same as CORTEX?</dt>
					<dd>MFN is additional to CORTEX, which is a cyber defence capability provided by the NCSC to New
						Zealand’s nationally significant organisations. MFN complements the existing threat detection
						and disruption service provided by the NCSC to consenting organisations.</dd>
				</div>

				<div>
					<dt>Will MFN provide all the protection I need?</dt>
					<dd>MFN has been developed to defend against current threats targeting New Zealand
						Organisations, but is intended to be used in parallel with other commercial cyber security
						products. MFN complements commercial threat intelligence by detecting and disrupting against
						indicators identified through NCSC’s advanced cyber defence capabilities and sourced
						from the NCSC’s international cyber security partnerships. While MFN has been developed to help
						defend against malicious activity impacting nationally significant organisations, including
						corporates and government organisations, when used in combination with other feeds it can help
						defend against the full spectrum of cyber threats impacting New Zealand. Cassini pairs the MFN
						feed together with the CERT NZ Threat Intelligence feed to further increase the benefit to
						users. Cassini is also able to integrate both of these feeds into your existing security
						framework to uplift any current capabilities your organisation might already have deployed.</dd>
				</div>

				<div>
					<dt>What does it mean if I block activity from an MFN indicator?</dt>
					<dd>MFN indicators generally reflect signs of activity from advanced threat actors.</dd>
				</div>

				<div>
					<dt>Why would I want to provide telemetry back to the NCSC when I block something on the MFN
						indicator list?</dt>
					<dd>MFN threat intelligence customers who provide telemetry back to the NCSC will help to
						increase MFN’s value and effectiveness, improving the NCSC’s knowledge of threats
						targeting New Zealand organisations and helping to lift New Zealand’s overall cyber
						security resilience. The NCSC will be able to provide better insights and resiliency advice to
						partners and their customers who provide telemetry back to the NCSC. Cassini CTI ensures that
						all obligations regarding telemetry are complied with. It is also up to the individual Cassini
						customer if they wish to provide simply Basic Telemetry, or Expanded Telemetry.
					</dd>
				</div>

				<div>
					<dt>Something we haven't covered?</dt>
					<dd>Contact us for any further questions at <a href="#">info@cassini.nz</a>.</dd>
				</div>

			</dl>

			<h3>Case studies - How MFN was able to help protect New Zealand organisations</h3>
			<dl class="case-studies">
				<div>
					<dt>Case Study 1: cyber crime prevention</dt>
					<dd>
						Through its advanced cyber defence capabilities, the National Cyber Security Centre (NCSC)
						identified a
						known criminal group exploiting a vulnerability in a widely used network device to deploy
						ransomware to
						victim networks in New Zealand. This activity was causing significant impact on affected
						networks.
						The NCSC was able to identify key indicators of this activity and within minutes had deployed
						them into
						the
						Malware Free Networks (MFN) threat intelligence service.
						The result was that within hours of the NCSC detecting malicious ransomware activity affecting
						New
						Zealand
						networks, customers using the MFN service were protected from it.
					</dd>
				</div>
				<div>
					<dt>Case Study 2: rapid deployment to mitigate risk from global compromise</dt>
					<dd>
						A widely used technology service provided by a trusted international vendor was compromised
						globally.
						The
						NCSC was able to use its international relationships to quickly understand the nature of the
						compromise
						and
						obtain information that could be used to defend New Zealand users of the affected service.
						Within hours
						of
						becoming aware of the compromise the NCSC had deployed indicators to MFN, enabling customers of
						the
						service
						to be automatically protected.
					</dd>
				</div>
			</dl>
		</div>
	</section>

	<section class="bottom-cta">
		<h2><strong>Protect</strong> your organisation today</h2>
		<div class="button alt"><a href="/sign-up/">Get Started Today</a></div>
	</section>
</div>