import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import { Auth } from 'aws-amplify';

@Component({
    selector: 'cassini-cti-app',
    templateUrl: './forgot-password.html'
})
export class ForgotPasswordComponent  {
    email: string;
    errorMessage: string;

    constructor(public router: Router) {
        this.errorMessage = null;
    }

    onNext() {
        this.errorMessage = null;

        Auth.forgotPassword(this.email)
            .then(data => {
                this.router.navigate(['/forgot-password', this.email]);
            })
            .catch(err => console.log(err));
    }
}
