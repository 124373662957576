import { ChangeDetectionStrategy, Component, NO_ERRORS_SCHEMA, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomerApiService } from 'src/app/service/customer.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { UniqueUsernameValidator } from './endpoint-unique-username-validator.directive';
import { UsernameValidator } from './endpoint-username-validator';


@Component({
    selector: 'app-saved-dialog',
    templateUrl: './add-endpoint.html',
    styleUrls: ['./add-endpoint.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class AddEndpointDialogComponent {
    form: UntypedFormGroup;
    hide = true;

    private usernameValidators = [
        Validators.minLength(8),
        UsernameValidator.cannotContainSpace,
    ];

    get username() { return this.form.get('username'); }
    get password() { return this.form.get('password'); }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public customerApiService: CustomerApiService,
        public dialogRef: MatDialogRef<AddEndpointDialogComponent>,
        private fb: UntypedFormBuilder
    ) { }

    ngOnInit(): void {
        // Configure forms using form builder
        this.form = this.fb.group({
            name: [""],
            description: [""],
            username: ["", [UsernameValidator.cannotContainSpace], UniqueUsernameValidator(this.data.authToken, this.data.customerId, this.customerApiService)],
            password: [""],
        });

        this.form.get('password').valueChanges.subscribe(value => {
            if (value) {
                this.updateUsernameValidators(true);
            } else {
                this.updateUsernameValidators(false);
            }
        });

        this.form.get('username').valueChanges.subscribe(value => {
            if (value) {
                this.updatePasswordValidators(true);
            } else {
                this.updatePasswordValidators(true);
            }
        });

    }

    updateUsernameValidators(required: boolean) {
        if (required) {
            this.form.get('username').setValidators(this.usernameValidators.concat(Validators.required));
        } else {
            this.form.get('username').setValidators(this.usernameValidators);
        }
        this.form.get('username').updateValueAndValidity({ emitEvent: false });
    }

    updatePasswordValidators(required: boolean) {
        if (required) {
            this.form.get('password').setValidators(Validators.required);
        } else {
            this.form.get('password').clearValidators();
        }
        this.form.get('password').updateValueAndValidity({ emitEvent: false });
    }

    createEndpoint() {
        const username = this.form.get('username').value == '' ? null : this.form.get('username').value;
        const password = this.form.get('password').value == '' ? null : this.form.get('password').value;

        const data = {
            name: this.form.get('name').value,
            description: this.form.get('description').value,
            username: username,
            password: password
        }

        this.dialogRef.close({
            success: true,
            data: data
        });
    }

    save() { }

    close() {
        this.dialogRef.close({
            success: false
        });
    }

}
