import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Contact } from '../shared/contact.model';
import { PortalUser } from '../shared/portal-user.model';

@Component({
    selector: 'app-add-edit-contact-dialog',
    templateUrl: './add-edit-contact.html',
    styleUrls: ['./add-edit-contact.scss']
})

export class AddEditContactDialogComponent {
    form: UntypedFormGroup;
    contact: Contact;
    private currentUser: PortalUser;

    constructor(
        public dialogRef: MatDialogRef<AddEditContactDialogComponent>,
        private fb: UntypedFormBuilder,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        this.contact = data.contact;
        this.currentUser = data.currentUser;
    }

    ngOnInit(): void {
        // If contact is undefined we are adding a new contact, otherwise 
        // we are editing an existing contact
        if (this.contact === null || this.contact === undefined) {
            // Configure forms using form builder
            this.form = this.fb.group({
                firstName: [""],
                lastName: [""],
                emailAddress: [""],
                phoneNumber: [""],
                role: [""],
                isPrimary: new UntypedFormControl({value: false, disabled: !this.currentUser.isAdmin})
            });
        } else {
            // A contact has been passed to the dialog so we are editing an existing contact
            // Configure forms using form builder
            const disableAdmin = (this.currentUser.id == this.contact.username || !this.currentUser.isAdmin);
            this.form = this.fb.group({
                firstName: [this.contact.first_name],
                lastName: [this.contact.last_name],
                emailAddress: [this.contact.email_address],
                phoneNumber: [this.contact.phone_number],
                role: [this.contact.role],
                isPrimary: new UntypedFormControl({value: this.contact.is_primary, disabled: disableAdmin})
            });           
        }
    }

    createContact() {
        const data = {
            firstName: this.form.get('firstName').value,
            lastName: this.form.get('lastName').value,
            emailAddress: this.form.get('emailAddress').value.trim(),
            phoneNumber: this.form.get('phoneNumber').value,
            role: this.form.get('role').value,
            isPrimary: this.form.get('isPrimary').value
        }

        this.dialogRef.close({
            success: true,
            data: data
         });
    }
    
    close() {
        this.dialogRef.close({
            success: false
         });
    }
}