// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  envName: 'development',

  region: 'ap-southeast-2',
  identityPoolId: 'ap-southeast-2:165f9aa6-abee-4c60-b967-3c27c53237e3',
  userPoolId: 'ap-southeast-2_LwOh5oqNf',
  clientId: 'e4ttfibajc9dps7r87ti0ggp8',
  cognito_idp_endpoint: '',
  cognito_identity_endpoint: '',
  sts_endpoint: '',

  grafana_login: 'https://dev-dashboard.cti.cassini.nz',
  //api_endpoint: 'http://localhost:5001',
  api_endpoint: 'https://portal.api.dev.cassini.nz',
  contact_api: 'https://contact.api.cassini.nz',
  contact_apikey: 'xnIL5X9ikG1SYkMsFZzOr2nGuZETYCvK1Fa2HTwF',
  support_email_address: 'support@cassini.nz'
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
