import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CustomerApiService } from 'src/app/service/customer.service';
import { Feed } from '../shared/feed.model';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, UntypedFormControl } from "@angular/forms";
import { FeedAuth } from '../shared/feed-auth.model';
import { throwError } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SavedDialogComponent } from 'src/app/shared/saved-dialog.component';
import { Auth } from 'aws-amplify';
import { PortalUser } from '../shared/portal-user.model';
import { ThemePalette } from '@angular/material/core';

import * as moment from 'moment';
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'cassini-cti-app',
    templateUrl: './feed-details.html',
    styleUrls: ['./feed-details.scss']
})
export class FeedDetailsComponent implements OnInit {

    dialogRef: MatDialogRef<SavedDialogComponent>;
    currentUser: PortalUser;
    moment =  moment;

    form: UntypedFormGroup;
    feeds: Feed[];
    hide = true;
    loading = true;
    slideColor: ThemePalette = 'accent';

    grantTypes = [
        {
            name: "Client Credential",
            value: "client_credential"
        }
    ]

    constructor(
        private titleService: Title,
        public router: Router,
        public customerService: CustomerApiService,
        public dialog: MatDialog,
        private fb: UntypedFormBuilder) {
            this.titleService.setTitle("Cassini CTI | Feed details");
    }

    ngOnInit(): void {
        Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(
            // User is authenticated
            user => {
                const token = user.getSignInUserSession().getIdToken().getJwtToken();
                this.currentUser = new PortalUser(user.attributes, token);

                this.fetchFeedDetails();
            },
            // User isn't authenticated.
            any => {
                this.router.navigate(['/login']);
            }
        );
    }

    formGroups() {
        return (<UntypedFormArray>this.form.controls.feeds).controls;
    }

    fetchFeedDetails() {
        // Finally fetch the feed details
        this.customerService.getFeeds(this.currentUser.authToken, this.currentUser.customerId).subscribe(result => {
            this.feeds = result;

            // Configure forms using form builder
            this.form = this.fb.group({
                feeds: this.fb.array(
                    this.feeds.map(feed => {
                        if (feed.auth_type.toLowerCase() == 'basic') {
                            let username = feed.auth !== undefined ? feed.auth.username : '';
                            let password = feed.auth !== undefined ? feed.auth.password : '';
                            return this.fb.group({
                                id: [feed.id],
                                endpoint_url: [feed.endpoint_url],
                                api_root: [feed.api_root],
                                collections: [feed.collections],
                                sighting_collection: [feed.sighting_collection],
                                name: [feed.description],
                                description: [feed.description],
                                enabled: new UntypedFormControl({value: feed.enabled, disabled: !this.currentUser.isAdmin}),                                
                                auth_type: [feed.auth_type],
                                username: [username, Validators.required],
                                password: [password, Validators.required],
                            })
                        } else {
                            let access_token_url = feed.auth !== undefined ? feed.auth.access_token_url : '';
                            let client_id = feed.auth !== undefined ? feed.auth.client_id : '';
                            let client_secret = feed.auth !== undefined ? feed.auth.client_secret : '';
                            let grant_type = feed.auth !== undefined ? feed.auth.grant_type : '';
                            let scope = feed.auth !== undefined ? feed.auth.scope : '';
                            return this.fb.group({
                                id: [feed.id],
                                endpoint_url: [feed.endpoint_url],
                                api_root: [feed.api_root],
                                collections: [feed.collections],
                                sighting_collection: [feed.sighting_collection],
                                name: [feed.description],
                                description: [feed.description],
                                enabled: [feed.enabled],
                                auth_type: [feed.auth_type],
                                access_token_url: [access_token_url, Validators.required],
                                client_id: [client_id, Validators.required],
                                client_secret: [client_secret, Validators.required],
                                grant_type: [grant_type, Validators.required],
                                scope: [scope]
                            })
                        }
                    })
                )
            });

            // set loading to false to hide the spinner and display the accordian with the feed details
            this.loading = false;
        });
    }

    submitFeed(feed, controls) {
        const auth_type = controls.value.auth_type;
        let auth = null;
        if (auth_type.toLowerCase() === 'basic') {
            auth = new FeedAuth(controls.value.username, controls.value.password)
        } else if (auth_type.toLowerCase() === 'oauth 2.0') {
            auth = new FeedAuth(null, null, controls.value.access_token_url, controls.value.client_id,
                controls.value.client_secret, controls.value.grant_type, controls.value.scope);
        } else {
            // Unsupported authentication type
            throwError(new Error('Unsupported authentication type specified'));
        }

        // Assign the new feed auth object to the feed object
        feed.auth = auth;
        feed.enabled = controls.value.enabled;
        this.customerService.updateFeed(this.currentUser.authToken, this.currentUser.customerId, feed)
            .subscribe(result => {
                this.dialogRef = this.dialog.open(SavedDialogComponent, {
                    disableClose: false,
                    height: 'auto'
                });

                this.dialogRef.afterClosed().subscribe(result => {
                    this.dialogRef = null;
                });
            })
    }
}
