<div class="content">
    <section class="page">
        <div class="container ">

            <form class="form-signin" role="form">
                <div class="form-group">
                    <h2>Forgot Password</h2>
                </div>
                <div *ngIf="errorMessage!=null" class="alert alert-danger">
                    {{ errorMessage }}
                </div>
                <div class="form-group">
                    <label class="control-label" for="signupEmail">Email</label>
                    <input id="signupEmail" type="email" maxlength="50" class="form-control" [(ngModel)]="email"
                        [ngModelOptions]="{standalone: true}">
                </div>
                <div class="form-group">
                    <button (click)="onNext()" id="nextPage" type="submit" class="btn btn-cassini-primary btn-block">Next
                    </button>
                </div>

                <hr>
                <p><a [routerLink]="['/login']">Login</a>
                </p>
            </form>


        </div>
    </section>
</div>