import { CognitoUser } from 'amazon-cognito-identity-js';

export class PortalUser {

    constructor(
        attributes,
        authToken: string
    )
    {
        this.id = attributes['sub'];
        this.customerId = attributes['custom:customer_id'];
        this.firstName = attributes['given_name'];
        this.lastName = attributes['family_name'];        
        this.email = attributes['email'];
        this.isAdmin = (attributes['custom:is_primary'].toLowerCase() === 'true');

        this.authToken = authToken
    }

    id: string;
    customerId: string;
    firstName: string;
    lastName: string;
    email: string;
    authToken: string;
    isAdmin: boolean;

    name() {
        return this.firstName + ' ' + this.lastName;
    }

}