import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { AmplifyService } from 'aws-amplify-angular';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    title = 'cassini-cti-portal';

    private authStateSub$;
    public isLoggedIn: boolean;

    public constructor(
        public router: Router,
        private amplifyService: AmplifyService
    ) { }

    ngOnInit(): void {
        this.authStateSub$ = this.amplifyService.authStateChange$
            .subscribe(authState => {
                this.isLoggedIn = authState.state === 'signedIn';
            });

        Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(
            // User is authenticated
            user => {
                this.isLoggedIn = true;
            },
            // User isn't authenticated.
            any => {
                this.isLoggedIn = false;
            }
        );
    }

    ngOnDestroy(): void {
        this.authStateSub$.unsubscribe();
    }

    navigateHome() {
        if (this.isLoggedIn) {
            this.router.navigate(['/portal/']);
        } else {
            this.router.navigate(['/home/']);
        }
    }
}
