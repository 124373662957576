import {Component, OnInit} from "@angular/core";
import { Title } from "@angular/platform-browser";


@Component({
    selector: 'cassini-cti-app',
    templateUrl: './terms.html',
    styleUrls: ['./terms.component.scss',]
})
export class TermsComponent implements OnInit {

    public constructor(
        private titleService: Title
    ) { 
        this.titleService.setTitle("Cassini CTI | Terms of Service");
    }

    ngOnInit() {
    }
}


