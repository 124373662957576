<h3>Add New User:</h3>
<form *ngIf="form" [formGroup]="form">
    <div class="form-group">
        <mat-form-field class="mat-form-field-400">
            <label></label>
            <input matInput required placeholder="First Name" maxlength="40" formControlName="firstName">
        </mat-form-field>
    </div>
    <div class="form-group">
        <mat-form-field class="mat-form-field-400">
            <label></label>
            <input matInput required placeholder="Last Name" maxlength="50" formControlName="lastName">
        </mat-form-field>
    </div>
    <div class="form-group">
        <mat-form-field class="mat-form-field-400">
            <label></label>
            <input matInput required placeholder="Email Address" formControlName="emailAddress">
        </mat-form-field>
    </div>
    <div class="form-group">
        <mat-form-field class="mat-form-field-400">
            <label></label>
            <input matInput placeholder="Phone Number" formControlName="phoneNumber">
        </mat-form-field>
    </div>
    <div class="form-group">
        <mat-form-field class="mat-form-field-400">
            <label></label>
            <input matInput placeholder="Role" formControlName="role">
        </mat-form-field>
    </div>
    <div class="adminCheckbox">
        <mat-checkbox placeholder="Admin" formControlName="isPrimary">Is Administrator</mat-checkbox>
    </div>
    <button class="mat-raised-button"(click)="close()">Cancel</button>&nbsp;&nbsp;
    <button type="submit" mat-raised-button class="btn btn-success"
        (click)="createContact();" [disabled]="form.invalid" >Save</button>
</form>