<h2>Feed Details</h2>
<p>
    View your feed configuration and update your authentication details on this page.
</p>

<mat-progress-spinner *ngIf="loading" [mode]="'indeterminate'" [color]="'accent'" class="spinner">
</mat-progress-spinner>
<form *ngIf="form" [formGroup]="form">
    <mat-accordion>
        <div formArrayName="feeds" *ngFor="let feedFormGroup of formGroups(); let i = index;">
            <div [formGroupName]="i">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title class="feed-name">
                            {{ feeds[i].name }}
                        </mat-panel-title>
                        <mat-panel-description class="feed-description">
                            {{ feeds[i].enabled ? "Active" : "Disabled" }}
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="form-group">
                        <label>Description:</label>
                        <div>{{ feeds[i].description }}</div>
                    </div>
                    <div class="form-group">
                        <label>Enabled:</label>
                        <div>
                            <mat-slide-toggle
                                formControlName="enabled"
                                [color]="slideColor"
                                [checked]="feeds[i].enabled">
                            </mat-slide-toggle>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Date of last fetch:</label>
                        <div>{{ moment(feeds[i].last_fetch).fromNow() }} ({{ feeds[i].last_fetch }})</div> 
                    </div>
                    <div class="form-group">
                        <label>Date of next fetch:</label>
                        <div>{{ moment(feeds[i].next_fetch).fromNow() }} ({{ feeds[i].next_fetch }})</div> 
                    </div>
                    <div class="form-group">
                        <label>Date most recent indicator was added to provider feed:</label>
                        <div>{{ moment(feeds[i].last_date_added).format("ddd Do MMMM YYYY, h:mm:ss a") }} ({{ feeds[i].last_date_added }})</div> 
                    </div>
                    <div *ngIf="!feeds[i].provided_on_behalf">
                        <div class="form-group">
                            <label>Endpoint Url:</label>
                            <div>{{ feeds[i].endpoint_url }}</div>
                        </div>
                        <div class="form-group">
                            <label>Authentication Type:</label>
                            <div>{{ feeds[i].auth_type }}</div> 
                        </div>
                        <div *ngIf="currentUser.isAdmin">
                            <div *ngIf="feeds[i].auth_type.toLowerCase() == 'basic'; else oauth">
                                <div class="form-group">
                                    <label>Basic Auth Credentials:</label>
                                    <p>
                                        Enter your organisations credentials below.
                                    </p>
                                    <mat-form-field class="mat-form-field-400">
                                        <label></label>
                                        <input matInput required placeholder="Username" formControlName="username"
                                            value="{{ feeds[i].auth?.username }}">
                                    </mat-form-field>
                                    <br />
                                    <mat-form-field class="mat-form-field-400">
                                        <label></label>
                                        <input matInput required placeholder="Password" [type]="hide ? 'password' : 'text'"
                                            formControlName="password" value="{{ feeds[i].auth?.password }}">
                                        <mat-icon matSuffix (click)="hide = !hide">
                                            {{hide ? 'visibility_off' : 'visibility'}}
                                        </mat-icon>
                                        <mat-hint>Enter your password</mat-hint>
                                    </mat-form-field>
                                </div>
                            </div>

                            <ng-template #oauth>
                                <div class="form-group">
                                    <label>OAuth 2.0 Credentials:</label>
                                    <p>
                                        Enter your organisations credentials below.
                                    </p>
                                    <mat-form-field class="mat-form-field-400">
                                        <label></label>
                                        <input matInput required placeholder="Client ID" formControlName="client_id"
                                            value="{{ feeds[i].auth?.client_id }}">
                                    </mat-form-field>
                                    <br />
                                    <mat-form-field class="mat-form-field-400">
                                        <label></label>
                                        <input matInput required placeholder="Client Secret" [type]="hide ? 'password' : 'text'"
                                            formControlName="client_secret" value="{{ feeds[i].auth?.client_secret }}">
                                        <mat-icon matSuffix (click)="hide = !hide">
                                            {{hide ? 'visibility_off' : 'visibility'}}
                                        </mat-icon>
                                    </mat-form-field>
                                    <br />
                                    <mat-form-field class="mat-form-field-800">
                                        <label></label>
                                        <input matInput required placeholder="Access Token Url"
                                            formControlName="access_token_url"
                                            value="{{ feeds[i].auth?.access_token_url }}">
                                    </mat-form-field>
                                    <br />
                                    <mat-form-field class="mat-form-field-400">
                                        <label></label>
                                        <mat-select formControlName="grant_type">
                                            <mat-option required *ngFor="let grantType of grantTypes"
                                                [value]="grantType.value">
                                                {{grantType.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <br />
                                    <mat-form-field class="mat-form-field-400">
                                        <label></label>
                                        <input matInput placeholder="Scope" formControlName="scope"
                                            value="{{ feeds[i].auth?.scope}}">
                                        <mat-hint>Scope is not required for some feed providers</mat-hint>
                                    </mat-form-field>
                                </div>
                            </ng-template>                                    
                        </div>
                    </div>
                    <div *ngIf="currentUser.isAdmin">
                        <mat-action-row>
                            <button type="submit" mat-raised-button class="btn btn-success"
                                (click)="submitFeed(feeds[i], formGroups()[i])">Save</button>
                        </mat-action-row>
                    </div>                    
                </mat-expansion-panel>
            </div>
        </div>
    </mat-accordion>
</form>
<p>

</p>