<div class="content">
    <section class="page">
        <div class="container ">
            <form class="form-signin" role="form" *ngIf="!setNewPassword" [hidden]="mfaChallenge">
                <div class="form-group">
                    <h2>Login</h2>
                </div>
                <div *ngIf="errorMessage!=null" class="alert alert-danger">
                    {{ errorMessage }}
                </div>
                <div *ngIf="!mfaSetup">
                    <div class="form-group">
                        <label class="control-label" for="signupEmail">Email</label>
                        <input id="signupEmail" required type="email" maxlength="100" class="form-control"
                            [(ngModel)]="email" [ngModelOptions]="{standalone: true}">
                    </div>

                    <div class="form-group">
                        <label class="control-label" for="signupPassword">Password</label>
                        <input id="signupPassword" required type="password" class="form-control"
                            length="40" [(ngModel)]="password" [ngModelOptions]="{standalone: true}">
                    </div>
                    <div *ngIf="invalidCredentials" class="alert alert-danger">
                        Incorrect email or password.
                    </div>
                    <div class="form-group">
                        <button (click)="signIn()" id="signupSubmit" type="submit"
                            class="btn btn-cassini-primary btn-block">
                            Login
                        </button>
                    </div>

                    <hr>
                    <p>
                        <a [routerLink]="['/forgot-password']"> Forgot Password?</a><br />
                    </p>

                </div>                
            </form>

            <div class="form-signin" [hidden]="!mfaSetup">
                <div class="form-group">
                    <h2>Register MFA</h2>
                </div>
                <div class="form-group qrcontainer">
                    <qr-code [value]="qrcode" size="300" errorCorrectionLevel="M"></qr-code>
                </div>
            </div>

            <div class="form-signin" [hidden]="!mfaChallenge">
                <div class="form-group">
                    <h2>Verification code</h2>
                </div>
                <div class="form-group">
                    <input #mfaCode id="mfaCode" required class="form-control" autocomplete="off">
                </div>
                <div *ngIf="invalidCode" class="alert alert-danger">
                    Incorrect code, please try again.
                </div>
            </div>

            <form class="form-signin" role="form" *ngIf="setNewPassword">
                <div class="form-group">
                    <h2>Set new password</h2>
                </div>                
                <div class="form-group">
                    <label class="control-label" for="signupEmail">Username / Email</label>
                    <input id="signupEmail" type="email" maxlength="50" class="form-control"
                        [(ngModel)]="email" [ngModelOptions]="{standalone: true}">
                </div>
                <div class="form-group">
                    <label class="control-label" for="existingPassword">Temporary Password</label>
                    <input id="existingPassword" type="password" class="form-control"
                        placeholder="at least 8 characters" length="40" [(ngModel)]="password"
                        [ngModelOptions]="{standalone: true}">
                </div>
                <div class="form-group">
                    <label class="control-label" for="signupPassword">New Password</label>
                    <input id="signupPassword" type="password" class="form-control"
                        placeholder="at least 14 characters" length="40" [(ngModel)]="newPassword"
                        [ngModelOptions]="{standalone: true}">
                    <small id="passwordHelp" class="form-text text-muted">Must include a number, upper, lower and special characters, and be at least 14 characters long.</small>
                </div>
                <div *ngIf="errorMessage!=null" class="alert alert-danger">
                    {{ errorMessage }}
                </div>
                <div class="form-group">
                    <button (click)="signIn()" id="signupSubmit" type="submit" class="btn btn-cassini-primary btn-block">
                        Set your password
                    </button>
                </div>
                <hr>
                <p>Already have an account? <a [routerLink]="['/login']">Login</a>
                </p>
            </form>

        </div>
    </section>
</div>