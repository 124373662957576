import { Component, ViewChild, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CustomerApiService } from 'src/app/service/customer.service';
import { Contact } from '../shared/contact.model';
import { MatDialogConfig, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteDialogComponent } from 'src/app/shared/confirm-delete-dialog.component';
import { ContactDataSource } from './contact-datasource.component';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AddEditContactDialogComponent } from './add-edit-contact.component';
import { Auth } from 'aws-amplify';
import { PortalUser } from '../shared/portal-user.model';
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'cassini-cti-app',
    templateUrl: './user-details.html',
    styleUrls:[ './user-details.scss' ]
})
export class UserDetailsComponent implements OnInit {
    
    currentUser: PortalUser;

    addContactDialogRef: MatDialogRef<AddEditContactDialogComponent>;
    confirmDeletetDialogRef: MatDialogRef<ConfirmDeleteDialogComponent>;
    displayedColumns: Array<string>;
    dataSource: ContactDataSource;
    loading = true;
    contactCount = 10;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;

    constructor(
        private titleService: Title,
        public router: Router,
        public customerApiService: CustomerApiService,
        public dialog: MatDialog) {
            this.titleService.setTitle("Cassini CTI | User details");
    }

    ngOnInit(): void {
        this.dataSource = new ContactDataSource(this.customerApiService);
        this.dataSource.count.subscribe(val => this.paginator.length = val);
        this.dataSource.loading$.subscribe(result => {
            this.loading = result;
        })

        Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(
            // User is authenticated
            user => {
                const token = user.getSignInUserSession().getIdToken().getJwtToken();
                this.currentUser = new PortalUser(user.attributes, token);

                // Only display the delete column if the current user is an admin
                if (this.currentUser.isAdmin) {
                    this.displayedColumns = ['created', 'name', 'email', 'phone', 'role', 'admin', 'edit', 'delete'];
                } else {
                    this.displayedColumns = ['created', 'name', 'email', 'phone', 'role', 'admin', 'edit'];
                }

                // reset the paginator after sorting
                this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

                merge(this.sort.sortChange, this.paginator.page)
                    .pipe(
                        tap(() => this.loadContactsPage())
                    )
                    .subscribe();

                this.loadContactsPage();
            },
            // User isn't authenticated.
            any => {
                this.router.navigate(['/login']);
            }
        ).catch(
            reason => {
                console.error(reason);
            }
        )
    }

    loadContactsPage() {
        this.dataSource.loadContacts(
            this.currentUser.authToken,
            this.currentUser.customerId,
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize);
    }

    addContact() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.position = {
            'top': '300px',
        };
        dialogConfig.data = {
            "contact": null,
            "currentUser": this.currentUser
        };

        this.addContactDialogRef = this.dialog.open(AddEditContactDialogComponent, dialogConfig);
        this.addContactDialogRef.afterClosed().subscribe(result => {
            this.addContactDialogRef = null;
            if (result.success) {
                this.loading = true;
                this.customerApiService.addContact(
                    this.currentUser.authToken,
                    this.currentUser.customerId,
                    result.data.firstName,
                    result.data.lastName,
                    result.data.emailAddress,
                    result.data.phoneNumber,
                    result.data.role,
                    result.data.isPrimary
                ).subscribe(result => {
                    this.loading = false;
                    this.loadContactsPage();
                },
                error => {
                    this.loading = false;
                    console.error('addContact error handler')
                    throw error;
                });
            }
        });
    }

    deleteContact(contact: Contact) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.position = {
            'top': '300px',
        };

        this.confirmDeletetDialogRef = this.dialog.open(ConfirmDeleteDialogComponent, dialogConfig);
        this.confirmDeletetDialogRef.afterClosed().subscribe(result => {
            this.confirmDeletetDialogRef = null;
            if (result) {
                this.loading = true;
                this.customerApiService.deleteContact(
                    this.currentUser.authToken,
                    this.currentUser.customerId,
                    contact.id
                ).subscribe(result => {
                    this.loading = false;
                    this.loadContactsPage();
                },
                error => {
                    this.loading = false;
                    console.error('addContact error handler')
                    throw error;
                });
            }
        });
    }

    editContact(contact: Contact) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.position = {
            'top': '300px',
        };
        dialogConfig.data = {
            "contact": contact,
            "currentUser": this.currentUser
        };

        this.addContactDialogRef = this.dialog.open(AddEditContactDialogComponent, dialogConfig);
        this.addContactDialogRef.afterClosed().subscribe(result => {            
            this.addContactDialogRef = null;
            if (result.success) {
                this.loading = true;

                this.customerApiService.updateContact(
                    this.currentUser.authToken,
                    this.currentUser.customerId,
                    contact.id,
                    result.data.firstName,
                    result.data.lastName,
                    result.data.emailAddress,
                    result.data.phoneNumber,
                    result.data.role,
                    result.data.isPrimary
                ).subscribe(result => {
                    this.loading = false;
                    this.loadContactsPage();                    
                },
                error => {
                    this.loading = false;
                    console.error('addContact error handler')
                    throw error;
                });
            }
        });
    }
}
