<div class="content">
    <section>
        <div class="container flex">
            <div class="text ">
                <h2>Proactive Protection</h2>
                <p>
                    Cassini's focus is to provide <strong>proactive</strong> protection for our customers. We aim to
                    block malicous activity on your network before your organisation is compromised.
                </p>
                <p>
                    Traditional security providers have focused on detection and response as their approach to cyber
                    security incident management. The problem with this is that it is entirely
                    <strong>recactive</strong>. By the time you are aware of the issue, the damage is done and you are
                    spending your time and energy trying to contain it.
                </p>
            </div>
            <div class="image">
                <img src="/assets/img/door.jpg" alt="Photo by Morgane Perraud on Unsplash"
                    title="Photo by Morgane Perraud on Unsplash" />
            </div>
        </div>
    </section>


    <section>
        <div class="container flex">
            <div class="text ">
                <h2>Trusted Partners</h2>
                <p>
                    Cassini's approach is to disrupt the malicious activity to prevent the harm from occuring at
                    all. This is why Cassini partners with the GCSB's NCSC and CERT NZ.
                </p>
                <p>
                    The Malware Free Networks (MFN) feed and CERT NZ feed are both high
                    quality, New Zealand specific, and actionable. The indicators in these feeds can be immediately
                    tasked into your network infrastructure without having to go through a manual approval process.
                    This means when you use Cassini CTI, it can be as fast as a couple of minutes
                    between a partner adding an indicator to a feed and malicious traffic being disrupted on your
                    network.
                </p>
                <p>
                    Find out more about <a routerLink="/what-is-mfn/" title="What is MFN?">Malware Free Networks
                        (MFN)?</a> and the <a routerLink="/what-is-certnz-phishing/"
                        title="What is the CERT NZ Phishing feed?">CERT NZ Phishing feed</a>.
                </p>
            </div>
            <div class="image">
                <img src="/assets/img/gcsb_logo.png" alt="GCSB logo" title="GCSB logo" />
                <img src="/assets/img/certnz-everyday-rgb-pos.png" alt="CERT NZ logo" title="CERT NZ logo" />
            </div>
        </div>
    </section>

    <section>
        <div class="container flex">
            <div class="text ">
                <h2>Enhanced situational <strong>Awareness</strong></h2>
                <p>If there was suspicious activity occuring on your organisation's network would you know? Would you
                    even know what to look for?</p>
                <p><strong>Cassini CTI </strong> not only helps protect your business against cyber threats, but also
                    alerts you when
                    they occur so you can take action.</p>
            </div>
            <div class="image">
                <img src="/assets/img/dashboard.png" alt="Dashboard Screenshot" class="screenshot " />
            </div>
        </div>
    </section>

    <section>
        <div class="container flex">
            <div class="text ">
                <h2>Reduced integration cost</h2>
                <p>
                    Avoid expensive sofware development projects and their ongoing support and maintenance costs.
                    <strong>Cassini CTI</strong>
                    enables organisations to get the maximum benefit from the trusted threat intelligence provided by
                    CERT NZ and the NCSC
                    for a low monthly cost.
                </p>
                <p>
                    Cassini takes care of the multiple different authentication schemes, indicator lifecycle management
                    (indicator expiry, refresh and revocation)
                    and return of telemetry data to the feed providers.
                </p>
            </div>
            <div class="image">
                <img src="/assets/img/code.jpg" alt="Photo by Chris Ried on Unsplash"
                    title="Photo by Chris Ried on Unsplash" />
            </div>
        </div>
    </section>

    <section>
        <div class="container flex">
            <div class="text ">
                <h2>Feed Provider compliant <strong>Reporting</strong></h2>
                <p><strong>Cassini CTI SaaS</strong> reports sighting information back to Feed
                    Providers ensuring that customers comply with the feed requirements.</p>
                <p>
                    Cassini CTI supports multiple different reporting formats including STIX 2.0 / TAXII 2.0. This
                    relieves you from the burden of having to convert sighting information into a specific format or
                    protocol to comply with your feed providers requirements.
                </p>
                <p>
                    Using Cassini insulates your organisations from any changes by the feed providers. If the feed
                    providers
                    make a change, Cassini will ensure we comply with that change meaning our customers don't need to
                    worry about
                    expensive software engineering projects to keep pace with version upgrades or API changes.
                </p>
            </div>
            <div class="image">
                <img src="/assets/img/stix.png" alt="Network Diagram" />
            </div>
        </div>
    </section>

    <section class="bottom-cta">
        <h2><strong>Protect</strong> your organisation today</h2>
        <div class="button alt"><a href="/sign-up/">Get Started Today</a></div>
    </section>
</div>

<router-outlet></router-outlet>