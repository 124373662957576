<header>
    <div class="container">
        <div class="company-name">
            <a (click)="navigateHome()"><div></div></a>

        </div>
        <nav>
            <ng-template [ngIf]="isLoggedIn" [ngIfElse]="elseBlock">
                <a routerLink="/portal/logout/" class="highlight active">Logout</a>
            </ng-template>
            <ng-template #elseBlock>
                <a routerLink="/pricing/" title="Cassini CTI pricing information" routerLinkActive="active">Pricing</a>
                <a routerLink="/sign-up/" title="Sign up for the Cassini CTI service" routerLinkActive="active">Sign Up</a>
                <a routerLink="/why-cassini/" title="Why use Cassini CTI?" routerLinkActive="active">Why Cassini</a>
                <a routerLink="/faq/" title="Frequently asked questions about Cassini CTI" routerLinkActive="active">FAQ</a>
                <a routerLink="/about/" title="Who is Cassini" routerLinkActive="active">About Us</a>
                <a routerLink="/terms/" ttile="Cassini CTI SaaS Terms of use" routerLinkActive="active">Terms</a>
                <a routerLink="/login-landing/" title="Login to the Cassini CTI portal" routerLinkActive="active" class="highlight">Login</a>
            </ng-template>
        </nav>
    </div>
</header>

<router-outlet></router-outlet>

<footer>
    <div class="container">
        <div class="footer-columns">
            <ul class="footer-links">
                <li><a routerLink="/pricing/" routerLinkActive="active">Pricing</a></li>
                <li><a routerLink="/sign-up/" routerLinkActive="active">Sign Up</a></li>
            </ul>
            
            <ul class="footer-links">
                <li><a routerLink="/why-cassini/" routerLinkActive="active">Why Cassini?</a></li>
                <li><a routerLink="/faq/" routerLinkActive="active">FAQ</a></li>
            </ul>

            <ul class="footer-links">
                <li><a routerLink="/what-is-mfn/" routerLinkActive="active">What is MFN?</a></li>
                <li><a routerLink="/what-is-certnz-phishing/" routerLinkActive="active">What is CERTNZ Phishing?</a></li>                
            </ul>

            <ul class="footer-links">
                <li><a routerLink="/about/" routerLinkActive="active">About Us</a></li>
                <li><a routerLink="/contact/" routerLinkActive="active">Contact</a></li>
            </ul>
            
            <ul class="footer-links">
                <li><a routerLink="/terms/" routerLinkActive="active">Terms</a></li>
            </ul>
        </div>
        <p class="copyright">&copy; 2019 Cassini Limited. • <a routerLink="/terms/" routerLinkActive="active">Terms</a>
            &amp; <a routerLink="/terms/" routerLinkActive="active">Privacy</a></p>
        <p class="iconcredit">Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a></p>
    </div>
</footer>
