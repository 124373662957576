import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Auth } from 'aws-amplify';


@Component({
    selector: 'cassini-cti-app',
    template: ''
})
export class LogoutComponent implements OnInit {
    
    constructor(public router: Router) {
    }

    ngOnInit() {
        Auth.signOut()
            .then(data => {
                // Send the user to the home page.
                this.router.navigate(['/']);
            });
    }
 }
