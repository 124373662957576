<div class="content">
	<section class="page">
		<div class="container">

			<h2>Get in touch today</h2>
			<p class="subtext">We're always keen to talk, whether you want to know more about Cassini CTI, our
				consulting services, or
				just want to shoot the breeze. You can contact us by using the form below or shooting us an email at <a
					href="mailto:info@cassini.nz">info@cassini.nz</a></p>
			<div class="contact-box">
				<div class="contact-details">

					<form [formGroup]="contactForm">
						<h3>Contact Form</h3>
						<div class="form-group">
							<mat-form-field class="mat-form-field-800">
								<label>Subject:</label>
								<input matInput type="text" formControlName="subjectCtrl">						
							</mat-form-field>
							<div *ngIf="subjectCtrl.invalid && (subjectCtrl.dirty || subjectCtrl.touched)"
								class="alert alert-danger">
								<div *ngIf="subjectCtrl.errors.required">
									Please provide a subject for your enquiry.
								</div>
							</div>
						</div>
						<div class="form-group">
							<mat-form-field class="mat-form-field-800">
								<label>Message Details:</label>
								<textarea matInput formControlName="bodyCtrl" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="10"></textarea>						
							</mat-form-field>
							<div *ngIf="bodyCtrl.invalid && (bodyCtrl.dirty || bodyCtrl.touched)"
								class="alert alert-danger">
								<div *ngIf="bodyCtrl.errors.required">
									Please provide some details about your enquiry.
								</div>
							</div>
						</div>
						<button type="submit" class="btn btn-cassini-primary" [disabled]="contactForm.invalid"
							(click)="submitContact()">Send</button>
					</form>
				</div>
			</div>

		</div>
	</section>
</div>