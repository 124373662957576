import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Customer } from '../portal/shared/customer.model';
import { Feed } from '../portal/shared/feed.model';
import { EndpointListResults } from '../portal/shared/endpoint-list-results.model';
import { ContactListResults } from '../portal/shared/contact-list-results.model';
import { Endpoint } from '../portal/shared/endpoint.model';
import { Contact } from '../portal/shared/contact.model';


@Injectable()
export class CustomerApiService {
    private static _router: Router;

    constructor(private http: HttpClient,
        private router: Router) {
    }

    private static _handleError(err: HttpErrorResponse | any) {
        if (err.status === 401 || err.status === 403) {
            // redirect back to an unauthorised page
            CustomerApiService._router.navigate(['unauthorised']);
        } else {
            console.error(err.message);
        }
        return throwError(err.error || err.json() || err || 'Server error');
    }


    // GET a customers details by id.
    getCustomer(authToken: string, id: string): Observable<Customer> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': authToken
            })
        };

        return this.http
            .get<Customer>(`${environment.api_endpoint}/customers/${id}/`, httpOptions)
            .pipe(map(res => res),
                catchError(CustomerApiService._handleError));
    }

    // GET a customers feed details.
    getFeeds(authToken: string, customerId: string): Observable<Feed[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': authToken
            })
        };

        return this.http
            .get<Feed[]>(`${environment.api_endpoint}/customers/${customerId}/feeds/`, httpOptions)
            .pipe(map(res => res),
                catchError(CustomerApiService._handleError));
    }

    // PUT a customers feed to update the auth credentials and enabled state
    updateFeed(authToken: string, customerId: string, feed: Feed): Observable<object> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': authToken
            })
        };

        return this.http
            .put(`${environment.api_endpoint}/customers/${customerId}/feeds/${feed.id}/`, feed, httpOptions)
            .pipe(
                retry(2),
                map(res => res),
                catchError(CustomerApiService._handleError));
    }

    // GET a customers endpoint details.
    getEndpoints(
        authToken: string,
        customerId: string,
        sortOrder: string = 'desc',
        sortBy: string = 'created',
        pageNumber: number = 0,
        pageSize: number = 10): Observable<EndpointListResults> {

        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': authToken
            }),
            params: new HttpParams()
                .set('sortBy', sortBy)
                .set('sortOrder', sortOrder)
                .set('pageNumber', pageNumber.toString())
                .set('pageSize', pageSize.toString())
        }

        return this.http
            .get<EndpointListResults>(`${environment.api_endpoint}/customers/${customerId}/endpoints/`, httpOptions)
            .pipe(map(res => res),
                catchError(CustomerApiService._handleError));
    }

    // POST to check that endpoint username is unique
    checkEndpointUsername(
            authToken: string,
            customerId: string,
            username: string,
        ): Observable<{[key: string]: any}> {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': authToken
                })
            };
            let body = {
                "username": username
            };
    
            return this.http
                .post<{[key: string]: any}>(`${environment.api_endpoint}/customers/${customerId}/endpoints/check-username/`, body, httpOptions)
                .pipe(
                    retry(2),
                    map(result => {
                        if (result.username_exists) {
                            return result;
                        } else {
                            return null;
                        }
                    }),
                    catchError(CustomerApiService._handleError));
        }

    // POST to create a new Endpoint
    addEndpoint(
        authToken: string,
        customerId: string,
        name: string,
        description: string,
        username?: string,
        password?: string,
    ): Observable<Endpoint> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': authToken
            })
        };
        let body = {
            "name": name,
            "description": description,
            "username": username,
            "password": password
        };

        return this.http
            .post<Endpoint>(`${environment.api_endpoint}/customers/${customerId}/endpoints/`, body, httpOptions)
            .pipe(
                retry(2),
                map(res => res),
                catchError(CustomerApiService._handleError));
    }

    deleteEndpoint(authToken: string, customerId: string, endpointId: string): Observable<object> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': authToken
            })
        };

        return this.http
            .delete(`${environment.api_endpoint}/customers/${customerId}/endpoints/${endpointId}/`, httpOptions)
            .pipe(
                retry(2),
                map(res => res),
                catchError(CustomerApiService._handleError));
    }

    // GET a customers contact details.
    getContacts(
        authToken: string,
        customerId: string,
        sortOrder: string = 'desc',
        sortBy: string = 'created',
        pageNumber: number = 0,
        pageSize: number = 10): Observable<ContactListResults> {

        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': authToken
            }),
            params: new HttpParams()
                .set('sortBy', sortBy)
                .set('sortOrder', sortOrder)
                .set('pageNumber', pageNumber.toString())
                .set('pageSize', pageSize.toString())
        }

        return this.http
            .get<ContactListResults>(`${environment.api_endpoint}/customers/${customerId}/contacts/`, httpOptions)
            .pipe(map(res => res),
                catchError(CustomerApiService._handleError));
    }

    // POST to create a new contact
    addContact(
        authToken: string,
        customerId: string,
        first_name: string,
        last_name: string,
        email: string,
        phone: string,
        role: string,
        is_primary: boolean
    ): Observable<Contact> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': authToken
            })
        };
        let body = {
            "first_name": first_name,
            "last_name": last_name,
            "email_address": email,
            "phone_number": phone,
            "role": role,
            "is_primary": is_primary
        };

        return this.http
            .post<Contact>(`${environment.api_endpoint}/customers/${customerId}/contacts/`, body, httpOptions)
            .pipe(
                map(res => res),
                catchError(CustomerApiService._handleError));
    }

    // Used to delete a contact (user)
    deleteContact(authToken: string, customerId: string, contactId: string): Observable<object> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': authToken
            })
        };

        return this.http
            .delete(`${environment.api_endpoint}/customers/${customerId}/contacts/${contactId}/`, httpOptions)
            .pipe(
                map(res => res),
                catchError(CustomerApiService._handleError));
    }

    // PUT to edit an exsiting contact
    updateContact(
        authToken: string,
        customerId: string,
        contactId: string,
        first_name: string,
        last_name: string,
        email: string,
        phone: string,
        role: string,
        is_primary: boolean
    ): Observable<Contact> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': authToken
            })
        };
        let body = {
            "id": contactId,
            "first_name": first_name,
            "last_name": last_name,
            "email_address": email,
            "phone_number": phone,
            "role": role,
            "is_primary": is_primary
        };

        return this.http
            .put<Contact>(`${environment.api_endpoint}/customers/${customerId}/contacts/${contactId}/`, body, httpOptions)
            .pipe(
                map(res => res),
                catchError(CustomerApiService._handleError));
    }
}
