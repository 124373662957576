import { Component } from '@angular/core';
import { Auth } from 'aws-amplify';


@Component({
    template: '<div class="content"> \
                    <section class="page"> \
                        <div class="container "> \
                                <h2>Oops!</h2> \
                                <p class="subtext">The page you are looking for does not exist.</p> \
                                <p class="subtext"><a routerLink="{{this.homeUrl}}">Home</a></p> \
                        </div> \
                    </section> \
                </div>'
})

export class PageNotFoundComponent  {
    public homeUrl: string;


    constructor() {
        Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(
            // User is authenticated
            user => {
                this.homeUrl = "/portal/";
            },
            // User isn't authenticated.
            any => {
                this.homeUrl = "/";
            }
        );        
    }
}
